import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/scheduleProfile.css'
import { Calendar } from 'react-multi-date-picker'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useExportToExcel from '../hooks/useExportToExcel'
import '../styles/attendanceList.css'
import { Download, Save } from 'lucide-react'
import { CircleCheckBig } from 'lucide-react'
import CyclingInput from '../components/CyclingInput'

const NewAttendanceListForTeacher = () => {
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const { tableRef, exportTableToExcel } = useExportToExcel()

  const [classrooms, setClassrooms] = useState([])

  const [selectedClassroom, setSelectedClassroom] = useState({}) // by default

  const [students, setStudents] = useState([])

  const [selectedDates, setSelectedDates] = useState([])

  const [isNotificationModalOn, setNotificationModalOn] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [duplicateDates, setDuplicateDates] = useState([])

  const [earliestDate, setEarliestDate] = useState(null)
  const [furthestDate, setFurthestDate] = useState(null)

  // maybe it is better not to restrict?
  // const today = new Date().setHours(0, 0, 0, 0) // this to unable in calendar all previous days from today

  // useEffect(() => {
  //
  // }, [selectedDates])

  const weekDays = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za']

  const months = [
    'Jan',
    'Feb',
    'Mrt',
    'Apr',
    'Mei',
    'Juni',
    'Juli',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ]

  useEffect(() => {
    const fetchClassrooms = async () => {
      // setErrorMessage('')
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/classrooms?status=true`
        )
        setClassrooms(data)
        //
      } catch (error) {
        // setTimeout(() => {
        //   setErrorMessage('')
        // }, 2000)
        // setErrorMessage(error.response.data.message)
      }
    }
    fetchClassrooms()
  }, [])

  const [studentsAttendance, setStudentsAttendance] = useState([])

  const handleDateChange = (newDates) => {
    setSelectedDates(newDates)
    const updatedAttendanceData = []
    // generate initial attendance records for all students and selected dates
    students.forEach((student) => {
      newDates.forEach((date) => {
        const attendanceRecord = {
          // classroomId: selectedClassroom._id,
          studentId: student._id,
          date: date,
          status: '',
        }
        updatedAttendanceData.push(attendanceRecord)
      })
    })
    setStudentsAttendance(updatedAttendanceData)

    // handle the earliest selected date and the furthest one so that we can send it in query and get those students that have the selected classroom between those dates
    if (newDates.length > 0) {
      const sortedDates = [...newDates].sort((a, b) => a - b)
      setEarliestDate(sortedDates[0])
      setFurthestDate(sortedDates[sortedDates.length - 1])
    } else {
      setEarliestDate(null)
      setFurthestDate(null)
    }
  }

  useEffect(() => { }, [earliestDate, furthestDate])

  const handleAttendanceChange = (e, studentId, date) => {
    // Find the attendance record for the corresponding studentId and date
    const attendanceRecordIndex = studentsAttendance.findIndex(
      (record) => record.studentId === studentId && record.date === date
    )

    // if (attendanceRecordIndex === -1) {
    //   // Attendance record doesn't exist in studentsAttendance, add a new one
    //   // Can it be that there is not? Maybe we don't need this if statement
    //   const newRecord = {
    //     classroomId: selectedClassroom._id,
    //     studentId: studentId,
    //     date: date,
    //     status: e.target.value,
    //   }
    //   setStudentsAttendance((prevAttendanceData) => [
    //     ...prevAttendanceData,
    //     newRecord,
    //   ])
    //
    // } else {
    // Attendance record found, update the status value
    const updatedAttendanceData = [...studentsAttendance]
    updatedAttendanceData[attendanceRecordIndex].status = e.target.value
    setStudentsAttendance(updatedAttendanceData)
    // }
  }

  useEffect(() => {
    const fetchStudentsByClassroom = async () => {
      try {
        if (selectedClassroom.name) {
          let queryString = `${process.env.REACT_APP_BACKEND_URL}/api/students/student-list-by-classroom/${selectedClassroom._id}?`

          if (earliestDate) {
            queryString += `earliestDate=${earliestDate}&`
          }

          if (furthestDate) {
            queryString += `furthestDate=${furthestDate}&`
          }

          const { data } = await axiosPrivate.get(queryString)
          setStudents(data)

          // Generate initial attendance records for all students and selected dates
          const updatedAttendanceData = []
          data.forEach((student) => {
            selectedDates.forEach((date) => {
              const attendanceRecord = {
                // classroomId: selectedClassroom._id,
                studentId: student._id,
                date: date,
                status: '',
              }
              updatedAttendanceData.push(attendanceRecord)
            })
          })

          setStudentsAttendance(updatedAttendanceData)
        } else {
          setStudents([]) // make the list of students in the table empty if selectedClassroom = ""
          setStudentsAttendance([]) // clear studentsAttendance when no classroom is selected
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchStudentsByClassroom() // Call the fetchData function when 'selectedClassroom' changes
  }, [selectedClassroom.name, selectedDates, earliestDate, furthestDate])

  const submitSchedule = async () => {
    try {
      if (selectedDates.length === 0 && !selectedClassroom._id) {
        setErrorMessage('Please choose a class and date(s)')
        return
      }
      if (selectedDates.length === 0) {
        setErrorMessage('Please choose a date(s)')
        return
      }
      if (!selectedClassroom._id) {
        setErrorMessage('Please choose a class')
        return
      }
      const payload = {
        selectedDates,
        selectedClassroom,
        studentsAttendance,
      }

      //
      const { data } = await axiosPrivate.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/lists/attendance-list-for-teacher/new`,
        JSON.stringify(payload)
      )

      setConfirmationMessage(data.message)
    } catch (error) {
      if (error.response?.status === 409) {
        // if there a conflict of duplicate dates
        setErrorMessage('De geselecteerde data bestaan ​​al voor deze les')
        setDuplicateDates(error.response.data)
      } else {
        setErrorMessage('Het maken van een schema is niet gelukt')
      }
    } finally {
      setNotificationModalOn(true)
    }
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
    return ''
  }

  return (
    <section className='popupContainer'>
      {isNotificationModalOn && (
        <div
          className='closeModalContainer'
        >
          <div className='closeConfirmationBox'>
            {confirmationMessage && <p>{confirmationMessage}</p>}
            {errorMessage && (
              <>
                <p>{errorMessage}</p>
                {/* if the error is regarding the duplicate dates, show them */}
                {duplicateDates?.length > 0 &&
                  duplicateDates?.map((date, dateIndex) => (
                    <p key={dateIndex}>{formatDate(date?.split('T')[0])}</p>
                  ))}
              </>
            )}
            <div className='confirmationButtonsContainer'>
              <button
                onClick={() => {
                  if (confirmationMessage) {
                    // it is more likely that we create multiple attendance lists at one session
                    // so if the creation has been successful
                    // we want to leave the picked/selected dates as they are on the calendar and drop only the selected classroom
                    setSelectedClassroom({})
                  }
                  setNotificationModalOn(false)
                  setConfirmationMessage('')
                  setDuplicateDates([])
                  setErrorMessage('')
                }}
              >
                Ok
                <CircleCheckBig width='15' stroke='white' />
              </button>
            </div>
          </div>
        </div>
      )}
      <button onClick={() => navigate(-1)} className='popupContainerClose'>
        <img src='/images/close.png' alt=''></img>
      </button>
      <div className='scheduleModule'>
        <div className='sectionContainer scheduleTableContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Presentielijst voor docent</h1>
            </div>

            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button
                  onClick={() =>
                    exportTableToExcel(`${selectedClassroom.name}`)
                  }
                  className='sectionControlButton'
                >
                  <Download stroke='white' width='15' />
                </button>
              </div>
              <div className='sectionControlContainer'>
                <button
                  onClick={() => submitSchedule()}
                  style={{ padding: 2 }}
                  className='sectionControlButton'
                >
                  <Save width='17' stroke='white' />
                </button>
              </div>
            </div>
          </div>
          {selectedClassroom.name && (
            <div className='centeredTableHeader'>
              <div colSpan={4 + selectedDates.length}>
                <p style={{ color: 'black', paddingBottom: '10px', textAlign: 'center !important' }}>{selectedClassroom.name}</p>
              </div>
            </div>
          )}
          <div className='listContainers-container'>
            <div className='listContainer listContainer-for-new'>
              <table className='list' ref={tableRef}>
                <thead>

                  {selectedDates.length > 0 && (
                    <tr>
                      <th className='stickyColumn' style={{ width: '200px' }} >Naam</th>
                      <th>LvH</th>
                      <th>Geslacht</th>
                      <th>Geb.datum</th>
                      {/* <th>Land</th> */}
                      {selectedDates
                        ?.sort((a, b) => a - b)
                        ?.map((date, index) => {
                          return (
                            <th key={index}>
                              {formatDate(
                                new Date(date).toISOString().split('T')[0]
                              )}
                            </th>
                          )
                        })}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {students?.map((student, studentIndex) => {
                    const birthDate = student.birthDate
                      ? formatDate(
                        new Date(student.birthDate)
                          .toISOString()
                          .split('T')[0]
                      )
                      : ''

                    return (
                      <tr key={studentIndex}>
                        <td className='cell stickyColumn px-for-td'>
                          {student.firstName} {student.lastName}
                        </td>
                        <td className='px-for-td'>
                          <em>{student.country}</em>
                        </td>
                        <td className='px-for-td'>{student.sex}</td>
                        <td className='px-for-td'>{birthDate}</td>
                        {selectedDates
                          .sort((a, b) => a - b)
                          ?.map((date, dateIndex) => {
                            const attendanceRecord = studentsAttendance.find(
                              (record) =>
                                record.studentId === student._id &&
                                record.date === date
                            )
                            return (
                              <td key={dateIndex} className='px-for-td'>
                                {/* <select
                                  onChange={(e) =>
                                    handleAttendanceChange(e, student._id, date)
                                  }
                                  value={attendanceRecord?.status || ''}
                                >
                                  <option value=''></option>
                                  <option value='Aanwezig'>Aanwezig</option>
                                  <option value='Afwezig'>Afwezig</option>
                                  <option value='Afgemeld'>Afgemeld</option>
                                </select> */}

                                <div className='protection_wrap'>
                                  <CyclingInput
                                    value={attendanceRecord?.status || ''}
                                    onChange={(e) =>
                                      handleAttendanceChange(e, student._id, date)
                                    }
                                  />
                                </div>
                              </td>
                            )
                          })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='scheduleDetailsSelection'>
          <div className='sectionContainer scheduleCalendarContainer'>
            <div>
              <Calendar
                multiple
                value={selectedDates}
                onChange={handleDateChange}
                // minDate={today}
                format='YYYY/MM/DD'
                locales={['en']}
                weekStartDayIndex={1}
                weekDays={weekDays}
                months={months}
                highlightToday={false}
                showOtherDays={true}
                hideWeekendDays={true}
              />
            </div>
          </div>
          <div className='sectionContainer scheduleScrollableBox'>

            {classrooms?.map((element, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedClassroom((prev) =>
                      prev.name === element.name ? {} : element
                    )
                  }}
                  className={
                    element.name === selectedClassroom.name
                      ? 'activeCategory categoryButton'
                      : 'categoryButton'
                  }
                >
                  <span className='nowrap'>{element.name}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewAttendanceListForTeacher









