import React, { useState } from 'react'
import { CirclePlus, CircleSlash2, CircleCheckBig } from 'lucide-react'

const StudentClassroomCard = ({
  index,
  studentClassroom,
  deleteClassroom,
  handleStudentClassroomInput,
  studentClassrooms,
  classrooms,
  // getCurrentDate,
  // booleanList,
  formattedDate,
}) => {
  const [deleteButtonActivated, setDeleteButtonActivated] = useState(false)

  const cardStatus =
    !studentClassroom.startDate ||
    !studentClassroom.endDate ||
    studentClassroom.endDate >= formattedDate

  return (
    <div
      className={
        cardStatus
          ? 'studentActiveCardContainer'
          : 'studentInactiveCardContainer'
      }
    >
      {deleteButtonActivated ? (
        <div
          className={
            cardStatus
              ? 'studentCardDeleteConfirmationActiveBox'
              : 'studentCardDeleteConfirmationInactiveBox'
          }
        >
          <p className='deleteConfirmationMessage'>
            Weet u zeker dat u deze klas wilt verwijderen?
          </p>
          <div className='confirmationButtonsContainer'>
            <button
              onClick={() => {
                deleteClassroom(
                  studentClassroom._id || studentClassroom.uniqueId
                )

                setDeleteButtonActivated(false)
              }}
            >
              Ja
              <CircleCheckBig width='15' stroke='white'/>
            </button>
            <button className='nee-button' onClick={() => setDeleteButtonActivated(false)}>
              Nee
               <CircleSlash2 width='15' stroke='white'/>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='studentCardBoxDeleteButtonContainer'>
            <button
              className='studentCardBoxDeleteButton'
              onClick={() => {
                setDeleteButtonActivated(true)
              }}
            >
              <img alt=''
                className='studentCardBoxDeleteImage'
                src='/images/delete.png'
              />
            </button>
          </div>
        </>
      )}
      <div
        className={`studentCardBox ${
          cardStatus ? 'studentCardBoxActive' : 'studentCardBoxInactive'
        }`}
      >
        {/* <label htmlFor={`classroom${index}`}>Klas</label> */}
        <select
          id={`classroom${index}`}
          name='classroom'
          // className={
          //   studentClassroom.status === 'true'
          //     ? 'classroomNameActive'
          //     : 'classroomNameInactive'
          // }
          onChange={(e) => handleStudentClassroomInput(e, index)}
          value={studentClassrooms[index].classroom}
          // disabled={studentClassroom.status === 'false' ? true : false}
        >
          <option value=''>--Kies een klas--</option>
          {classrooms?.map((classroom, i) => {
            return (
              <option value={classroom._id} key={i}>
                {classroom.name}
              </option>
            )
          })}
        </select>

        {studentClassroom.classroom && (
          <div className='studentCardSubSection'>
            <label htmlFor={`startDate${index}`}>
              Van
              {/* <img
                title='Inclusief de gekozen datum'
                className='questionMarkIcon'
                src='/images/question.png'
              /> */}
            </label>

            <input
              type='date'
              id={`startDate${index}`}
              name='startDate'
              onChange={(e) => handleStudentClassroomInput(e, index)}
              value={studentClassrooms[index].startDate}
              // disabled={studentClassroom.status === 'false' ? true : false}
            ></input>
          </div>
        )}

        {studentClassroom.startDate && (
          <div className='studentCardSubSection'>
            <label htmlFor={`endDate${index}`}>
              Tot en met
              {/* <img
                title='Inclusief de gekozen datum'
                className='questionMarkIcon'
                src='/images/question.png'
              /> */}
            </label>

            <input
              type='date'
              id={`endDate${index}`}
              name='endDate'
              onChange={(e) => handleStudentClassroomInput(e, index)}
              value={studentClassrooms[index].endDate}
              min={studentClassroom.startDate}
              // disabled={studentClassroom.status === 'false' ? true : false}
            ></input>
          </div>
        )}
      </div>

      {/* {studentClassroom.classroom && studentClassroom.startDate && (
        <div className='studentCardExtraSection'>
          <div>
            
            <select
              name='status'
              className='classroomStatus'
              onChange={(e) => handleStudentClassroomInput(e, index)}
              value={studentClassroom.status}
            >
              {booleanList.map((status, index) => {
                return (
                  <option value={status} key={index}>
                    {status === 'true' ? 'Actief' : 'Inactief'}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      )} */}
    </div>
  )
}

export default StudentClassroomCard
