import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const OrganizationNewCard = ({
  setOrganizations,
  addNewOrganizationActivated,
  setAddNewOrganizationActivated,
}) => {
  const axiosPrivate = useAxiosPrivate()

  const [newOrganization, setNewOrganization] = useState({
    name: '',
    email: '',
    status: true,
  })
  const [errMsg, setErrMsg] = useState('')

  const closeNewOrganization = () => {
    setAddNewOrganizationActivated(false)
  }

  const handleInput = (event) => {
    let data = { ...newOrganization }
    data[event.target.name] = event.target.value
    setNewOrganization(data)
  }

  const addNewOrganization = async () => {
    try {
      const { data } = await axiosPrivate.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/organizations/organization`,
        newOrganization
      )
      setAddNewOrganizationActivated(false)
      setOrganizations(data)
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Geen serverreactie')
      } else if (error.response?.status === 400) {
        setErrMsg(error.response.data.message)
      } else if (error.response?.status === 409) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Update mislukt')
      }
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [newOrganization.name])

  return (
    <div
      className={`cardContainer ${
        addNewOrganizationActivated ? 'fancyCardContainer' : ''
      }`}
    >
      <div className='cardDeleteButtonContainer'>
        <button onClick={closeNewOrganization} className='cardDeleteButton'>
          <img className='cardDeleteImage' src='/images/delete.png' />
        </button>
      </div>
      {errMsg && (
        <div className='cardMessageContainer'>
          <p className='cardErrorMessage'>{errMsg}</p>
        </div>
      )}
      <div className='cardProfileImageContainer'>
        <img src='/images/organization.png'></img>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='Naam'
          className='cellInput'
          id='name'
          type='text'
          name='name'
          value={newOrganization.name}
          onChange={(e) => handleInput(e)}
        ></input>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='E-mailadres'
          className='cellInput'
          id='email'
          type='email'
          name='email'
          value={newOrganization.email}
          onChange={(e) => handleInput(e)}
        ></input>
      </div>

      <div className='cardStatusContainer'>
        <select
          name='status'
          onChange={(e) => handleInput(e)}
          value={newOrganization.status}
        >
          <option value={true}>Actief</option>
          <option value={false}>Inactief</option>
        </select>
      </div>

      <div className='cardUpdateButtonContainer'>
        <button onClick={addNewOrganization}>Creëren</button>
      </div>
    </div>
  )
}

export default OrganizationNewCard
