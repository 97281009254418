import React, { useState, useEffect } from 'react'
import { Printer } from 'lucide-react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import generateRandomColors from '../utils/generateRandomColors'

import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Loader from '../components/Loader'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const ByOrganization = ({ formatDate }) => {
  const axiosPrivate = useAxiosPrivate()
  const currentDate = new Date(Date.now())
  const formattedDate = currentDate.toISOString().split('T')[0]
  const [selectedDate, setSelectedDate] = useState(formattedDate)
  const [stats, setStats] = useState([])
  const [isLoading, setLoading] = useState(false)

  const [isRequestMade, setRequestMade] = useState(false)

  const dynamicBarColors = generateRandomColors(stats)

  const data = {
    labels: stats.map((element) => {
      return `${element.label} (${element.data})`
    }),
    datasets: [
      {
        data: stats.map((element) => element.data),
        backgroundColor: dynamicBarColors,
        borderColor: dynamicBarColors,
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
      title: {
        display: false,
        text: `Aantal studenten naar instantie op ${formatDate(selectedDate)}`,
        position: 'bottom',
      },
    },
  }

  const handleSubmit = async () => {
    if (!selectedDate) {
      return
    }
    try {
      setLoading(true)
      const { data } = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/statistics/by-organization?selectedDate=${selectedDate}`
      )

      setStats(data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleSubmit()
  }, [selectedDate])

  const handleDate = (e) => {
    setSelectedDate(e.target.value)
  }

  return (
    <>
      {isLoading && (
        <div className='sectionContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <Loader />
            </div>
          </div>
        </div>
      )}
      {!isLoading && selectedDate && (
        <div className='sectionContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>
                Aantal studenten naar instantie op {formatDate(selectedDate)}
              </h1>
            </div>
            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    window.print()
                  }}
                  className='sectionControlButton'
                >
                      <Printer stroke='white' width={15}/>
                </button>
              </div>
            </div>
          </div>

          <div>
            <Bar data={data} options={options} />
          </div>
        </div>
      )}

      {!isLoading && !selectedDate && (
        <div className='sectionContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Per instantie</h1>
            </div>
          </div>
          <p className='infoOnLoading'>
            U moet rechts een datum kiezen om het diagram te zien
          </p>
        </div>
      )}

      <div className='sectionContainer stripContainer'>
        <div className='sectionHeaderContainer'>
          <div className='sectionTitleContainer'>
            <div className='sectionTitlePoint'></div>
            <h1 className='sectionTitle'>Kies datum</h1>
          </div>
        </div>

        <div className='insertDetailsBox'>
          <div className='studentDetailsBox'>
            <input
              type='date'
              id='date'
              name='date'
              onChange={handleDate}
              value={selectedDate}
            ></input>
          </div>
        </div>
      </div>
    </>
  )
}

export default ByOrganization
