import React, { useState } from 'react'
import useAuth from '../hooks/useAuth'
import { Trash2, CircleSlash2, CircleCheckBig } from 'lucide-react'
import {
  useNavigate,
} from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const SchedulesTable = ({ schedules, setSchedules }) => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const columnHeaders = ['Klas', 'Periode', '']

  const handleRowClick = (scheduleId) => {
    navigate(`/lists/attendance-list-for-teacher/${scheduleId}`) // Navigate to the specified link
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [elementIdToDelete, setElementIdToDelete] = useState('')

  const handleDeleteConfirmation = (e, id) => {
    e.stopPropagation() // does not let the parent onClick trigger
    setElementIdToDelete(id)
    setShowDeleteConfirmation(true)
  }

  const handleDeleteCancel = (e) => {
    e.stopPropagation() // does not let the parent onClick trigger
    setShowDeleteConfirmation(false)
  }

  const handleScheduleDelete = async (e) => {
    try {
      e.stopPropagation() // does not let the parent onClick trigger

      const { data } = await axiosPrivate.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/lists/attendance-list-for-teacher/${elementIdToDelete}`
      )
      // setSchedules(data)

      const tempArray = [...schedules]
      const foundIndex = tempArray.findIndex(
        (element) => element._id === elementIdToDelete
      )
      tempArray.splice(foundIndex, 1)
      setSchedules(tempArray)
    } catch (error) {
    } finally {
      setShowDeleteConfirmation(false)
    }
  }

  return (
    <div className='listContainer listContainer-for-schedulesTable'>
      <table className='list'>
        <thead>
          <tr>
            {columnHeaders?.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {schedules?.map((element, index) => {
            const isAdmin = auth.roles.includes(1991)

            const startDate = formatDate(element?.dates[0]?.split('T')[0])
            const endDate = formatDate(
              element?.dates[element.dates.length - 1]?.split('T')[0]
            )

            const oneDate =
              // basically element.dates.length === 1 || the first element equals the last one
              element?.dates[0] === element?.dates[element.dates.length - 1]
                ? formatDate(element?.dates[0]?.split('T')[0])
                : null

            return (
              <tr key={index} onClick={() => handleRowClick(element._id)}>
                <td className='cell px-for-td nowrap'>{element?.classroom?.name}</td>
                <td className='cell middle_cell px-for-td '>
                  {oneDate ? oneDate : `${startDate} ... ${endDate}`}
                </td>


                {showDeleteConfirmation &&
                  elementIdToDelete === element._id ? (
                  <td className='cell trashcan_container px-for-td'>
                    <div className='deleteRowConfirmationBox'  >
                      <p className='deleteConfirmationMessage'>
                        Weet u zeker dat u deze schema wilt verwijderen?
                      </p>
                      <div className='confirmationButtonsContainer'>
                        <button style={{ width: '100px' }}
                          onClick={(e) => {
                            handleScheduleDelete(e)
                          }}
                        >
                          Ja
                          <CircleCheckBig width='15' stroke='white' />
                        </button>
                        <button className='nee-button' onClick={(e) => handleDeleteCancel(e)} style={{ width: '100px' }}>
                          Nee
                          <CircleSlash2 width='15' stroke='white' />
                        </button>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td className='cell trashcan_container px-for-td'>
                    <button
                      onClick={(e) => {
                        handleDeleteConfirmation(e, element._id)
                      }}
                      disabled={!isAdmin}
                      className={`noteIconContainer ${!isAdmin ? 'faded' : ''}`}
                    >
                      <Trash2 width='20' stroke='var(--dark_orange)' />
                    </button>
                  </td>
                )}

              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SchedulesTable
