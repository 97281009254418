import React, { useEffect, useState } from 'react';
import '../styles/buttonEffect.css';  

const CyclingInput = ({ value, onChange }) => {
  const items = ['Aanwezig', 'Afwezig', 'Afgemeld', ''];
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [buttonAnimate, setButtonAnimate] = useState(false);  

  useEffect(() => {
    if (value === '') {
      setCurrentIndex(-1);
    } else {
      setCurrentIndex(items.indexOf(value));
    }
  }, [value]);

  const handleClick = () => {
    const nextIndex = (currentIndex + 1) % items.length;
    setCurrentIndex(nextIndex);
    onChange(items[nextIndex]);

    // we add here button animation
    setButtonAnimate(true);
    setTimeout(() => {
      setButtonAnimate(false);
    }, 300);
  };

  const inputValue = currentIndex === -1 ? '' : items[currentIndex];

  return (
    <div className='cyclinginputButtonContainer'>
      <input type="text" value={inputValue} style={{maxWidth: '100%'}} readOnly />
      <button className={`cyclinginputButton ${buttonAnimate ? 'animate' : ''}`} onClick={handleClick}></button>
    </div>
  );
};

export default CyclingInput;
