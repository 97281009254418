import { useCallback, useRef } from 'react'
import * as XLSX from 'xlsx'

const useExportToExcel = () => {
  const tableRef = useRef(null)

  const exportTableToExcel = useCallback((filename) => {
    if (!tableRef.current) return

    // Get the table element
    const table = tableRef.current

    // Create a new workbook
    const wb = XLSX.utils.book_new()

    // Create a new worksheet
    const ws = XLSX.utils.aoa_to_sheet([])

    // Loop through the rows and cells of the table
    table.querySelectorAll('tr').forEach((row, rowIndex) => {
      const rowArray = []
      row.querySelectorAll('td, th').forEach((cell) => {
        // Check if the cell contains a select element
        const selectElement = cell.querySelector('select')
        if (selectElement) {
          // Get the selected option and its value
          const selectedOption =
            selectElement.options[selectElement.selectedIndex]
          const selectedValue = selectedOption ? selectedOption.textContent : ''
          rowArray.push(selectedValue)
        } else {
          rowArray.push(cell.textContent)
        }
      })
      XLSX.utils.sheet_add_aoa(ws, [rowArray], { origin: -1 })
    })

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate a FileSaver dialog to save the Excel file with the specified filename
    XLSX.writeFile(wb, `${filename}.xlsx`)
  }, [])

  return { tableRef, exportTableToExcel }
}

export default useExportToExcel
