import React, { useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { CircleSlash2, CircleCheckBig, Trash2 } from 'lucide-react'
import {
  useNavigate,
  Link,
  useParams,
  useLocation,
  // useHistory,
} from 'react-router-dom'
import '../styles/tables.css'
import useAuth from '../hooks/useAuth'

const StudentsList = ({
  students,
  setStudents,
  tableRef,
  setTotalStudentsCount,
}) => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate() // Access the history object

  const handleRowClick = (studentId) => {
    navigate(`/students/student/${studentId}`) // Navigate to the specified link
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  const [errorMessage, setErrorMessage] = useState('')

  const params = useParams()

  const columnHeaders = [
    'Voornaam',
    'Achternaam',
    'Geslacht',
    'Geb.datum',
    'LvH',
    'Reg.datum',
    'Klas',
    'Instantie',
    'Consulent',
    '', // for delete button
  ]

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [elementIdToDelete, setElementIdToDelete] = useState('')

  const handleDeleteConfirmation = (e, studentId) => {
    e.stopPropagation() // does not let the parent onClick trigger
    setElementIdToDelete(studentId)
    setShowDeleteConfirmation(true)
  }

  const handleDeleteCancel = (e) => {
    e.stopPropagation() // does not let the parent onClick trigger
    setShowDeleteConfirmation(false)
  }

  const submitStudentDelete = async (e) => {
    e.stopPropagation() // does not let the parent onClick trigger

    try {
      // previous version starts
      // const { data } = await axiosPrivate.delete(
      //   `/api/students/student/${elementIdToDelete}`
      // )
      // setStudents(data)
      // instead of getting data, wouldn't it make more sense to send the delete request and just splice the deleted element in the frontend?
      // because we might be deleting an item on some filters being on, in that case we have to write the logic of queries to the delete function
      // previous version ends

      const { data } = await axiosPrivate.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/students/student/${elementIdToDelete}`
      )

      if (data.message === 'Success') {
        setStudents((prevList) => {
          return prevList.filter(
            (element) => element.student._id !== elementIdToDelete
          )
        })

        setTotalStudentsCount((prev) => prev - 1)
      }
    } catch (error) {
      // setErrorMessage(error?.response?.data?.message)
    } finally {
      setShowDeleteConfirmation(false)
    }
  }

  return (
    <div className='listContainer'>
      <table ref={tableRef} className='list specificStudentsList'>
        <thead>
          <tr>
            {columnHeaders?.map((header, index) => (
              <th key={index}>
                <span className='nowrap'>{header}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {students?.map((element, index) => {
            const isAdmin = auth.roles.includes(1991)
            const birthDate = formatDate(
              element?.student?.birthDate?.split('T')[0]
            )

            const registrationDate = formatDate(
              element.student.registrationDate?.split('T')[0]
            )

            return (
              <tr
                key={element.student._id}
                onClick={() => handleRowClick(element.student._id)}
              >
                <td
                  className={`px-for-td cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element.student.firstName}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element.student.lastName}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element.student.sex}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {birthDate}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element.student.country}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {registrationDate}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element?.classrooms?.map((classroom, i, array) => (
                    <p key={classroom._id}>
                      {classroom.name}
                      {/* Add a comma if the current element is not the last one in the array */}
                      {i < array.length - 1 && ', '}
                    </p>
                  ))}
                </td>
                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element.student?.organization?.name}
                </td>

                <td
                  className={`px-for-td  cell ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {element.student.contactPersonName}
                </td>
                <td
                  className={`px-for-td  cell trashcan_container ${element.student.status === 'false' ? 'inactiveStudent' : ''
                    }`}
                >
                  {showDeleteConfirmation &&
                    elementIdToDelete === element.student._id ? (
                    <div className='deleteRowConfirmationBox'>
                      <p className='deleteConfirmationMessage'>
                        Weet u zeker dat u deze leerling wilt verwijderen?
                      </p>
                      <div className='confirmationButtonsContainer'>
                        <button onClick={(e) => submitStudentDelete(e)}>
                          Ja
                          <CircleCheckBig width='15' stroke='white' />
                        </button>
                        <button className='nee-button' onClick={(e) => handleDeleteCancel(e)}>
                          Nee
                          <CircleSlash2 width='15' stroke='white' />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={(e) => {
                        handleDeleteConfirmation(e, element.student._id)
                      }}
                      disabled={!isAdmin}
                      className={`noteIconContainer ${!isAdmin ? 'faded' : ''}`}
                    >
                      <Trash2 width='20' stroke='var(--dark_orange)' />
                    </button>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default StudentsList
