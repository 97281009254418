import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import '../styles/evaluations.css'
import Loader from '../components/Loader'

const Evaluations = () => {
  const axiosPrivate = useAxiosPrivate()

  const [evaluationList, setEvaluationList] = useState([])
  const [isLoading, setLoading] = useState(false)

  const handleEvaluationDone = async (id) => {
    try {
      setLoading(true)
      const { data } = await axiosPrivate.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/evaluations/evaluation/${id}`
      )

      if (data.message === 'Success') {
        setEvaluationList((prevList) =>
          prevList.filter((student) => student._id !== id)
        )
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchEvaluationList = async () => {
      try {
        setLoading(true)
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/evaluations`
        )

        setEvaluationList(data)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    fetchEvaluationList()
  }, [])

  return (
    <main>
      <div className='sectionContainer'>
        <div className='sectionHeaderContainer'>
          <div className='sectionTitleContainer'>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Evaluatielijst</h1>
              </>
            )}
          </div>
          <div className='sectionControlsContainer'>
            {/* <div className='sectionControlContainer'> */}
            <p className='evaluationInfo green'>Groen: 20 - 24 weken</p>
            <p className='evaluationInfo yellow'>Geel: 25 - 29 weken</p>
            <p className='evaluationInfo orange'>Oranje: 30 - 34 weken</p>
            <p className='evaluationInfo red'>Rode: 35 - meer weken</p>
            {/* </div> */}
          </div>
        </div>
        <div className='listPaginationContainer'>
          <div className='listContainer'>
            <table className='evaluationList'>
              <thead>
                <tr>
                  <th>Voornaam</th>
                  <th>Achternaam</th>
                  {/* <th>LvH</th> */}
                  {/* <th>Active days</th> */}
                  <th>Instantie</th>
                  <th>Actieve weken</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {evaluationList?.map((student) => {
                  return (
                    <tr key={student._id} className={student.color}>
                      <td className='cell'>{student.firstName}</td>
                      <td className='cell'>{student.lastName}</td>
                      <td className='cell'>{student.organization}</td>
                      {/* <td className='cell'>{student.country}</td> */}
                      {/* <td className='cell'>{student.totalActiveDays}</td> */}
                      <td className='cell'>{student.totalActiveWeeks}</td>

                      <td className='cell'>
                        <button
                          className='wordedButton'
                          onClick={() => handleEvaluationDone(student._id)}
                        >
                          Klaar
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Evaluations
