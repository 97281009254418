import { useNavigate, useLocation } from 'react-router-dom'
import '../styles/unauthorized.css'

const Unauthorized = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <section className='forgetPasswordContainer'>
      <p className='authText'>
        U bent niet geautoriseerd om deze pagina te bekijken
      </p>

      {/* <button className='authButton' onClick={() => navigate('/')}>
        Ga naar homepagina
      </button> */}
    </section>
  )
}

export default Unauthorized
