import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <section className='forgetPasswordContainer'>
      <p className='authText'>Pagina niet gevonden</p>
      <button className='authButton' type='button' onClick={goBack}>
        Ga terug
      </button>
    </section>
  )
}

export default NotFound
