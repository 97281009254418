import React, { useState } from 'react'
import BySex from '../statisticComponents/BySex'
import ByAge from '../statisticComponents/ByAge'
import '../styles/statistics.css'
import ByCountry from '../statisticComponents/ByCountry'
import ByOrganization from '../statisticComponents/ByOrganization'

const Statistics = () => {
  const [chosenCategory, setChosenCategory] = useState('Per geslacht') // by default

  const categories = [
    'Per geslacht',
    'Per leeftijd',
    'Per LvH',
    'Per instantie',
  ]

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  return (
    <main className='mainbg'>
      {/* <Header /> */}
      <section className='subsectionContainer'>
        <div className='sectionContainer stripContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Statistieken</h1>
            </div>
          </div>
          <div className='categoriesContainer'>
            {categories?.map((category, index) => {
              return (
                <div
                  onClick={() => setChosenCategory(category)}
                  className={`categoryButton ${
                    category === chosenCategory ? 'activeCategory' : ''
                  }`}
                  key={index}
                >
                  <span className='nowrap'>{category}</span>
                </div>
              )
            })}
          </div>
        </div>

        {chosenCategory === 'Per geslacht' ? (
          <BySex formatDate={formatDate} />
        ) : chosenCategory === 'Per leeftijd' ? (
          <ByAge formatDate={formatDate} />
        ) : chosenCategory === 'Per LvH' ? (
          <ByCountry formatDate={formatDate} />
        ) : chosenCategory === 'Per instantie' ? (
          <ByOrganization formatDate={formatDate} />
        ) : (
          <> </>
        )}
      </section>
    </main>
  )
}

export default Statistics
