import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useNavigate } from 'react-router-dom'
import '../styles/attendanceReport.css'
import useExportToExcel from '../hooks/useExportToExcel'
import '../styles/attendanceList.css'
import { Download } from 'lucide-react'

const NewAttendanceListForOrganization = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { tableRef, exportTableToExcel } = useExportToExcel()

  const [dates, setDates] = useState([])

  const [studentsAttendance, setStudentsAttendance] = useState([])

  const [prereqAttendanceReportData, setPrereqAttendanceReportData] = useState({
    organization: '',
    startDate: '',
    endDate: '',
  })

  const handleAttendanceReport = (e) => {
    if (studentsAttendance.length > 0) {
      // every time we start changing details of the search, we drop all previous students result
      setStudentsAttendance([])
    }
    const data = { ...prereqAttendanceReportData }

    data[e.target.name] = e.target.value

    setPrereqAttendanceReportData(data)
  }

  useEffect(() => {}, [prereqAttendanceReportData])

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/organizations?status=true`
        )
        setOrganizations(data)
      } catch (error) {
        // setErrMsg(error.response.data.message)
      }
    }

    fetchOrganizations()
  }, [])

  const fetchStudentsAttendance = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/lists/attendance-list-for-organization/${prereqAttendanceReportData.organization}?startDate=${prereqAttendanceReportData.startDate}&endDate=${prereqAttendanceReportData.endDate}`
      )

      setDates(data.dates)
      setStudentsAttendance(data.studentsAttendance)
    } catch (error) {}
  }

  return (
    <section className='popupContainer'>
      <button onClick={() => navigate(-1)} className='popupContainerClose'>
        <img src='/images/close.png' alt=''></img>
      </button>
      <div className='attendanceReportContainer'>
        <div className='sectionContainer attendanceForOrganizationSectionContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Presentielijst voor instanties</h1>
            </div>
            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button
                  onClick={() =>
                    exportTableToExcel(
                      `${
                        organizations.find(
                          (org) =>
                            org._id === prereqAttendanceReportData.organization
                        ).name
                      }_${dates[0]}_${dates[dates.length - 1]}`
                    )
                  }
                  className='sectionControlButton'
                >
                  <Download width='17' stroke='white'/>
                </button>
              </div>
            </div>
          </div>

          <div className='listContainer'>
            {studentsAttendance.length > 0 && (
              <table ref={tableRef} className='list'>
                <thead>
                  <tr>
                    <th className='stickyColumn' style={{minWidth: '120px'}} >Naam</th>
                    <th> Achternaam </th>
                    <th> LvH </th>
                    <th> Geslacht </th>
                    <th> Geb.datum </th>
                    <th> Instantie </th>
                    {dates?.map((header, index) => (
                      <th key={index}>{formatDate(header)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {studentsAttendance?.map((element, index) => {
                    const selectedOrganization = organizations.find(
                      (org) =>
                        org._id === prereqAttendanceReportData.organization
                    )
                    return (
                      <tr key={index}>
                        <td style={{minWidth: '120px'}} className='stickyColumn px-for-td'>{element?.student?.firstName}</td>
                        <td className='px-for-td '>{element?.student?.lastName}</td>
                        
                        <td className='px-for-td '>{element?.student?.country}</td>
                        <td className='px-for-td '>{element?.student?.sex}</td>
                        <td className='px-for-td '>
                          {formatDate(
                            element?.student?.birthDate?.split('T')[0]
                          )}
                        </td>
                        <td className='px-for-td '>{selectedOrganization.name}</td>
                        {dates?.map((date, dateIndex) => {
                          const foundStatus = element?.attendance?.find((e) => {
                            return date === e.date
                          })
                          return <td style={{padding: '10px 15px'}} key={dateIndex}>{foundStatus?.status}</td>
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className='sectionContainer stripContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Kies details</h1>
            </div>
            <div className='sectionControlsContainer'>
              {/* <div className='sectionControlContainer'>
                <button
                  onClick={fetchStudentsAttendance}
                  style={{ padding: 1.5 }}
                  className='sectionControlButton'
                >
                  <img
                    className='sectionControlImage'
                    src='/images/tick.png'
                  ></img>
                </button>
              </div> */}
            </div>
          </div>
          <div className='insertDetailsBox'>
            <div className='studentDetailsBox'>
              <label htmlFor='organization'>Instantie</label>
              <select
                id='organization'
                name='organization'
                onChange={handleAttendanceReport}
                value={prereqAttendanceReportData.organization || ''}
              >
                <option value={''}>--Kies een instantie--</option>
                {organizations?.map((org, index) => {
                  return (
                    <option value={org._id} key={index}>
                      {org.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='startDate'>Van</label>

              <input
                type='date'
                id='startDate'
                name='startDate'
                onChange={handleAttendanceReport}
                value={prereqAttendanceReportData.startDate}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='endDate'>Tot en met</label>
              <input
                type='date'
                id='endDate'
                name='endDate'
                onChange={handleAttendanceReport}
                value={prereqAttendanceReportData.endDate}
              ></input>
            </div>
            <button className='wordedButton' onClick={fetchStudentsAttendance}>
              Maak de lijst
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewAttendanceListForOrganization
