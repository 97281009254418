import React, { useState } from 'react'
import { Download, CircleSlash2, CircleCheckBig } from 'lucide-react'

const StudentAttachmentCard = ({
  studentAttachment,
  index,
  isAdmin,
  deleteAttachment,
}) => {
  const [deleteButtonActivated, setDeleteButtonActivated] = useState(false)
  const downloadUrl = `${process.env.REACT_APP_BACKEND_URL}/uploads/studentAttachments/${studentAttachment.file.fileName}`
  return (
    <div className='studentAttachmentContainer'>
      <div className='studentAttachmentInfoBox'>
        <p title={studentAttachment.file.originalName}>
          {studentAttachment.file.originalName.length > 30
            ? `${studentAttachment.file.originalName.substring(0, 30)}...`
            : studentAttachment.file.originalName}
        </p>
        <p>{studentAttachment.createdAt.split('T')[0]}</p>
      </div>

      {deleteButtonActivated ? (
        <div className='studentAttachmentDeleteConfirmationBox'>
          <p className='deleteConfirmationMessage'>
            Weet u zeker dat u deze bijlage wilt verwijderen?
          </p>
          <div className='confirmationButtonsContainer'>
            <button
              onClick={() => {
                deleteAttachment(index)
                setDeleteButtonActivated(false)
              }}
            >
              Ja
              <CircleCheckBig width='15' stroke='white'/>
            </button>
            <button className='nee-button' onClick={() => setDeleteButtonActivated(false)}>
              Nee
              <CircleSlash2 width='15' stroke='white'/>
            </button>
          </div>
        </div>
      ) : (
        <div className='studentAttachmentButtonsContainer'>
          {!isAdmin ? (
            <div></div>
          ) : (
            <div className='studentCardBoxDeleteButtonContainer'>
              <button
                onClick={() => setDeleteButtonActivated(true)}
                className='studentCardBoxDeleteButton'
              >
                <img alt=''
                  src='/images/delete.png'
                  className='studentCardBoxDeleteImage'
                />
              </button>
            </div>
          )}
          <div className='studentAttachmentDownloadBox'>
            <a href={downloadUrl} className='attachmentDownloadBox'>
              <div className='sectionControlImage'>
                 <Download width='15' stroke='white'/>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default StudentAttachmentCard