import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/scheduleProfile.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useExportToExcel from '../hooks/useExportToExcel'
import { Download, Save, CircleCheckBig } from 'lucide-react'
import '../styles/attendanceList.css'
import CyclingInput from '../components/CyclingInput'

const AttendanceListForTeacherProfile = () => {
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const params = useParams()
  const { tableRef, exportTableToExcel } = useExportToExcel()

  const [students, setStudents] = useState([])
  const [studentsAttendance, setStudentsAttendance] = useState([])
  const [schedule, setSchedule] = useState({})
  const [checkboxList, setCheckboxList] = useState([])
  const [isUpdating, setUpdating] = useState(false)
  const [updated, setUpdated] = useState(false)

  useEffect(() => {}, [studentsAttendance])

  const handleColumnCheckbox = (e, index, date) => {
    const newStatus = e.target.checked ? 'Aanwezig' : ''
    const tempCheckbox = [...checkboxList]
    tempCheckbox[index].checked = e.target.checked
    setCheckboxList(tempCheckbox)

    const updatedAttendanceData = studentsAttendance.map((data) => {
      if (data.date === date) {
        return { ...data, status: newStatus }
      }
      return data
    })

    // For students who don't have a record for the given date, create one
    students.forEach(student => {
      if (!updatedAttendanceData.some(data => data.student._id === student._id && data.date === date)) {
        updatedAttendanceData.push({ student: { _id: student._id }, date, status: newStatus })
      }
    })

    setStudentsAttendance(updatedAttendanceData)
  }

  const handleCyclingInputChange = (id, date, newValue) => {
    const attendanceRecordIndex = studentsAttendance.findIndex(
      (element) => element.student._id === id && element.date === date
    )

    if (attendanceRecordIndex !== -1) {
      const updatedAttendanceData = [...studentsAttendance]
      updatedAttendanceData[attendanceRecordIndex].status = newValue
      setStudentsAttendance(updatedAttendanceData)
    } else {
      // If the attendance record does not exist, create a new one
      const newAttendanceRecord = {
        student: { _id: id },
        date: date,
        status: newValue,
      }
      setStudentsAttendance([...studentsAttendance, newAttendanceRecord])
    }
  }

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/lists/attendance-list-for-teacher/${params.id}`
        )
  
        setStudents(data.students)
        setSchedule(data.schedule)
  
        // initialize studentsAttendance with the fetched data
        let initialAttendanceData = []

        data.students.forEach(student => {
          data.schedule.dates.forEach(date => {
            const attendanceRecord = data.studentsAttendance.find(
              record => record.student._id === student._id && record.date === date
            )
            if (attendanceRecord) {
              initialAttendanceData.push(attendanceRecord)
            } else {
              // if no attendance record exists, create one with empty status
              initialAttendanceData.push({
                student: { _id: student._id },
                date: date,
                status: ''
              })
            }
          })
        })
  
        setStudentsAttendance(initialAttendanceData)
  
        let tempCheckboxList = []
        for (let i = 0; i < data.schedule.dates.length; i++) {
          tempCheckboxList.push({
            date: data.schedule.dates[i],
            checked: false,
          })
        }
        setCheckboxList(tempCheckboxList)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchStudents()
  }, [])
  

  const submitScheduleUpdate = async () => {
    try {
      setUpdating(true)
      const studentsAttendanceList = studentsAttendance?.map((element) => {
        // we only need to send ids of attendance and presence status
        return {
          _id: element._id,
          status: element.status,
        }
      })

      const payload = {
        studentsAttendance: studentsAttendanceList,
        schedule: schedule,
      }

      const { data } = await axiosPrivate.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/lists/attendance-list-for-teacher/${schedule._id}`,
        JSON.stringify(payload)
      )

      if (data) {
        setTimeout(() => {
          setUpdated(false)
        }, 1000)
        setUpdated(true)
      }
    } catch (error) {
    } finally {
      setUpdating(false)
    }
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  return (
    <section className='popupContainer'>
      <button onClick={() => navigate(-1)} className='popupContainerClose'>
        <img src='/images/close.png' alt='' />
      </button>
      <div className='scheduleContainer'>
        <div className='sectionContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Presentielijst voor docent</h1>
            </div>
            <div className='sectionControlsContainer'>
              {isUpdating && (
                <div className='notificationMessageInControls'>
                  <img
                    alt=''
                    className='studentCardConfirmationButtonImage'
                    src='/animations/loading.gif'
                  />
                </div>
              )}
              {updated && (
                <div className='notificationMessageInControls2'>
                  <CircleCheckBig width='14' stroke='white' />
                </div>
              )}
              <div className='sectionControlContainer'>
                <button
                  onClick={() => exportTableToExcel(schedule?.classroom?.name)}
                  className='sectionControlButton'
                >
                  <Download width='17' stroke='white' />
                </button>
              </div>
              <div className='sectionControlContainer'>
                <button
                  onClick={() => submitScheduleUpdate()}
                  className='sectionControlButton'
                >
                  <Save width='17' stroke='white' />
                </button>
              </div>
            </div>
          </div>
          <div className='centeredTableHeader'>
            <div colSpan={4 + checkboxList.length}>
            <p style={{color: 'black', paddingBottom: '10px'}}>{schedule?.classroom?.name}</p>
            </div>
          </div>
          <div className='container-for-listContainer'>
            <div className='listContainer'>
              <table className='list' ref={tableRef}>
                <thead>
                  
                  <tr>
                    <th className='stickyColumn' >Naam</th>
                    <th>LvH</th>
                    <th>Geslacht</th>
                    <th>Geb.datum</th>
                    {checkboxList
                      ?.sort((a, b) => a.date - b.date)
                      ?.map((element, index) => (
                        <th key={index}>
                          <span className='nowrap checkboxoflist'>
                            <input
                              id={element.date}
                              type='checkbox'
                              checked={element.checked}
                              onChange={(e) =>
                                handleColumnCheckbox(e, index, element.date)
                              }
                            />{' '}
                            <label htmlFor={element.date}>
                              {formatDate(element.date.split('T')[0])}
                            </label>
                          </span>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {students?.map((student, index) => {
                    const birthDate = student.birthDate
                      ? formatDate(
                          new Date(student.birthDate)
                            .toISOString()
                            .split('T')[0]
                        )
                      : ''
                    return (
                      <tr key={index} className=''>
                        <td className='cell stickyColumn px-for-td'>
                          {student.firstName} {student.lastName}
                        </td>
                        <td className='px-for-td'>
                          <em>{student.country}</em>
                        </td>
                        <td className='px-for-td'>{student.sex}</td>
                        <td className='px-for-td'>{birthDate}</td>
                        {schedule.dates
                          .sort((a, b) => a - b)
                          ?.map((date, dateIndex) => {
                            const attendanceRecord = studentsAttendance.find(
                              (element) =>
                                element.student._id === student._id &&
                                element.date === date
                            )
                            const initialValue = attendanceRecord ? attendanceRecord.status : ''
                            return (
                              <td key={dateIndex}>
                                <CyclingInput
                                  value={initialValue}
                                  onChange={(newValue) =>
                                    handleCyclingInputChange(
                                      student._id,
                                      date,
                                      newValue
                                    )
                                  }
                                />
                              </td>
                            )
                          })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AttendanceListForTeacherProfile
