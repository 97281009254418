import React, { useEffect, useState } from 'react'
import '../styles/lists.css'
import { useNavigate, Link } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import SchedulesTable from './SchedulesTable'
import PrereqAttendanceReport from './PrereqAttendanceReport'
import AttendanceReport from '../pages/NewAttendanceListForOrganization'
import Loader from './Loader'
import { CirclePlus } from 'lucide-react'


const AttendanceListForTeachers = () => {
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()

  const [schedules, setSchedules] = useState([])
  const [isLoading, setLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [pagesCount, setPagesCount] = useState(0)

  const handlePreviousPage = () => {
    setPage((prev) => {
      if (prev === 1) return prev
      return prev - 1
    })
  }
  const handleNextPage = () => {
    setPage((prev) => {
      if (prev === pagesCount) return prev
      return prev + 1
    })
  }

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        setLoading(true)
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/lists/attendance-list-for-teachers?page=${page}`
        )
        setSchedules(data.list)
        setPagesCount(data.pagesCount)
      } catch (error) {
        console.log(error.message)
      } finally {
        setLoading(false)
      }
    }
    fetchSchedules()
  }, [page])
  return (
    <>
      <div className='sectionHeaderContainer'>
        <div className='sectionTitleContainer'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Voor docenten</h1>
            </>
          )}
        </div>
        <div className='sectionControlsContainer'>
          <div className='sectionControlContainer'>
            <button
              onClick={() => navigate('/lists/attendance-list-for-teacher/new')}
              className='sectionControlButton'
            >
              <CirclePlus stroke='white'  width='15'/>
            </button>
          </div>
        </div>
      </div>
      {schedules.length > 0 && (
        <div className='listPaginationContainer listPaginationContainer-attendance'>
          <SchedulesTable schedules={schedules} setSchedules={setSchedules} />

          <div className='listControls'>
            <section style={{display: 'flex'}}>
            <div className='listPagination' style={{margin: 'auto', flexGrow: '0'}}>
              <button
                disabled={page === 1}
                onClick={handlePreviousPage}
                className='paginationButton'
              >
                <img alt='' className='arrow' src='/images/previous.png' />
              </button>

              {Array.from({ length: pagesCount }, (_, index) => index + 1)?.map(
                (pageNumber) => {
                  if (
                    pageNumber <= 2 ||
                    pageNumber > pagesCount - 2 ||
                    Math.abs(pageNumber - page) <= 1
                  ) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => setPage(pageNumber)}
                        className={`paginationButton ${
                          page === pageNumber ? 'selectedPaginationButton' : ''
                        }`}
                      >
                        {pageNumber}
                      </button>
                    )
                  } else if (
                    pageNumber === 3 ||
                    pageNumber === pagesCount - 2
                  ) {
                    // Show ellipsis (...) for skipped pages
                    return <span key={pageNumber}>...</span>
                  }
                  return null
                }
              )}

              <button
                disabled={page === pagesCount}
                onClick={handleNextPage}
                className='paginationButton'
              >
                <img alt='' className='arrow' src='/images/next.png' />
              </button>
            </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

export default AttendanceListForTeachers
