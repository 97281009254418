import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'

const Layout = () => {
  return (
    <div className='root-div'>
        <div className='root-div-subcontainer'>
          <Navbar />
          <div className='outlet'> <Outlet /></div>
        </div>
    </div>
  )
}

export default Layout
