import React, { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import ClassroomCard from './ClassroomCard'
import ClassroomNewCard from './ClassroomNewCard'
import { CirclePlus } from 'lucide-react'

const Classrooms = () => {
  const axiosPrivate = useAxiosPrivate()
  const newClassRef = useRef()

  const [classrooms, setClassrooms] = useState([])

  const [addNewClassroomActivated, setAddNewClassroomActivated] = useState(
    false
  )
 

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/classrooms`
        )
        setClassrooms(data)
      } catch (error) {
      }
    }
    fetchClassrooms()
  }, [])

  const handleInput = (event, index) => {
    const data = [...classrooms]
    data[index][event.target.name] = event.target.value
    setClassrooms(data)
  }

  const handleDeleteClassroom = async (id) => {
    const { data } = await axiosPrivate.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/classrooms/classroom/${id}`
    )
    setClassrooms(data)
  }

  const addNewClassroom = () => {
    setAddNewClassroomActivated(true)
  }

  return (
    <>
      <div className='sectionHeaderContainer'>
        <div className='sectionTitleContainer'>
          <div className='sectionTitlePoint'></div>
          <h1 className='sectionTitle'>Klassen</h1>
        </div>
        <div className='sectionControlsContainer'>
          <div className='sectionControlContainer'>
            <button onClick={addNewClassroom} className='sectionControlButton'>
            <CirclePlus stroke='white'  width='15'/>
            </button>
          </div>
        </div>
      </div>
      <div className='cardsContainer'>
        {addNewClassroomActivated && (
          <ClassroomNewCard
            setClassrooms={setClassrooms}
            addNewClassroomActivated={addNewClassroomActivated}
            setAddNewClassroomActivated={setAddNewClassroomActivated}
          />
        )}
        {classrooms?.map((classroom, index) => {
          return (
            <ClassroomCard
              key={index}
              index={index}
              classroom={classroom}
              classrooms={classrooms}
              handleInput={handleInput}
              handleDeleteClassroom={handleDeleteClassroom}
            />
          )
        })}
      </div>
    </>
  )
}

export default Classrooms
