import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import AttendanceListForTeachers from '../components/AttendanceListForTeachers'

import useAxiosPrivate from '../hooks/useAxiosPrivate'

const Lists = () => {
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const [chosenCategory, setChosenCategory] = useState('Voor docenten') // by default

  const categories = ['Voor docenten', 'Voor instanties']

  return (
    <main className='mainbg'>
      <section className='subsectionContainer'>
        <div className='sectionContainer stripContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Presentielijst</h1>
            </div>
          </div>
          <div className='categoriesContainer'>
            {categories?.map((category, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setChosenCategory(category)
                    if (category === 'Voor instanties') {
                      navigate('/lists/attendance-list-for-organization/new')
                    }
                  }}
                  className={`categoryButton ${
                    category === chosenCategory ? 'activeCategory' : ''
                  }`}
                >
                  <span className='nowrap'>{category}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className='sectionContainer'>
          {chosenCategory === 'Voor docenten' && <AttendanceListForTeachers />}
        </div>
      </section>
    </main>
  )
}

export default Lists
