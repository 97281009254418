import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import '../../styles/studentProfile.css'
import StudentClassroomCard from '../../components/StudentClassroomCard'
import StudentStatusCard from '../../components/StudentStatusCard'
import useAuth from '../../hooks/useAuth'
import { v4 as uuidv4 } from 'uuid'
import { Save, Trash2 } from 'lucide-react'
import { CirclePlus, X, UserRound, CircleSlash2, CircleCheckBig } from 'lucide-react'

const NewStudent = () => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const uniqueId = uuidv4()

  const [student, setStudent] = useState({
    image: '',

    firstName: '',
    lastName: '',
    birthDate: '',
    sex: '',
    country: '',

    telephone: '',
    email: '',
    arrivalDate: '',
    registrationDate: '',

    status: '',
    statusDate: '',

    // react gives warning if select option equals null,
    // mongoose throws error if value equals empty string
    // since it expects a reference objectId
    organization: '',

    permitHolder: '',

    contactPersonName: '',
    contactPersonJobDescription: '',
    contactPersonTelephone: '',
    contactPersonEmail: '',
  })

  const [studentStatuses, setStudentStatuses] = useState([
    // {
    //   status: true,
    //   date: '',
    // },
  ])

  const [errMsg, setErrMsg] = useState('')

  const [studentClassrooms, setStudentClassrooms] = useState([])

  const [attachments, setAttachments] = useState([])
  const [isCloseConfirmationWindowOn, setCloseConfirmationWindowOn] = useState(
    false
  )

  const [nameValError, setNameValError] = useState('')
  const [classroomValError, setClassroomValError] = useState('')
  const [statusValError, setStatusValError] = useState('')

  const sexList = ['Man', 'Vrouw', 'X']
  const booleanList = ['true', 'false']
  const noteHeaders = ['Datum', 'Auteur', 'Categorie', 'Beschrijving']
  const categoryNames = ['Evaluatie', 'Status', 'Klas', 'Andere']
  const currentDate = new Date(Date.now())
  const formattedDate = currentDate.toISOString().split('T')[0]

  const [noteIndex, setNoteIndex] = useState(null)
  const [editedNote, setEditedNote] = useState({})

  const [notes, setNotes] = useState([])

  const [newNote, setNewNote] = useState({
    date: formattedDate,
    authorName: auth.userName,
    author: auth.userId,
    category: 'Andere', // by default
    description: '',
  })

  const handleEditedNoteInput = (event) => {
    const data = { ...editedNote }
    data[event.target.name] = event.target.value
    setEditedNote(data)
  }

  const openEditedNote = (index) => {
    setNoteIndex(index)
    setEditedNote(notes[index])
  }

  const cancelEditedNote = () => {
    setNoteIndex(null)
    setEditedNote({})
  }

  const saveEditedNote = () => {
    const data = [...notes]
    data[noteIndex] = editedNote
    setNotes(data)
    setNoteIndex(null)
    setEditedNote({})
  }

  const [filterToggle, setFilterToggle] = useState(false)
  const [newNoteToggle, setNewNoteToggle] = useState(false)

  const handleNewNoteInput = (event) => {
    let data = { ...newNote }
    data[event.target.name] = event.target.value
    setNewNote(data)
  }

  const cancelNewNote = () => {
    setNewNote({
      date: formattedDate,
      authorName: auth.userName,
      author: auth.userId,
      category: 'Andere', // by default
      description: '',
    })
    setNewNoteToggle(false)
  }

  const saveNewNote = () => {
    const data = [...notes]
    data.unshift(newNote)
    setNotes(data)
    setNewNote({
      date: formattedDate,
      authorName: auth.userName,
      author: auth.userId,
      category: 'Andere', // by default
      description: '',
    })
    setNewNoteToggle(false)
  }

  const deleteNote = (index) => {
    const data = [...notes]
    data.splice(index, 1)
    setNotes(data)
  }

  const [classrooms, setClassrooms] = useState([])
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/classrooms`
        )
        setClassrooms(data)
      } catch (error) {
        setErrMsg(error.response.data.message)
      }
    }
    fetchClassrooms()
  }, [])

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/organizations`
        )
        setOrganizations(data)
        //
      } catch (error) {
        setErrMsg(error.response.data.message)
      }
    }

    fetchOrganizations()
  }, [])

  const handleStudentInput = (event) => {
    let data = { ...student }
    data[event.target.name] = event.target.value
    setStudent(data)
  }

  const addClassroom = () => {
    const data = [...studentClassrooms]
    data.unshift({
      classroom: '',
      startDate: '',
      endDate: '',
      // status: 'true',
      uniqueId: uniqueId,
    })
    setStudentClassrooms(data)
  }

  const addStatus = () => {
    // we want to make sure that the user is reminded of adding a note regarding the new status
    setStatusValError('Vergeet niet een aantekening toe te voegen')
    setTimeout(() => {
      setStatusValError('')
      const data = [...studentStatuses]

      // we want to create active and inactive statuses in turns
      // and also if there is no initial status, we want to create the active one
      if (data[0]?.status !== 'true' || data.length === 0) {
        data.unshift({
          status: 'true',
          date: '',
          uniqueId: uniqueId,
        })
      } else {
        data.unshift({
          status: 'false',
          date: '',
          uniqueId: uniqueId,
        })
      }
      setStudentStatuses(data)
    }, 2000)
  }

  const deleteClassroom = (id) => {
    const filteredClassrooms = studentClassrooms.filter(
      (element) => element.uniqueId !== id
    )
    setStudentClassrooms(filteredClassrooms)
  }

  const deleteStatus = (id) => {
    const filteredStatuses = studentStatuses.filter(
      (element) => element.uniqueId !== id
    )
    setStudentStatuses(filteredStatuses)
  }

  const handleStudentClassroomInput = (event, index) => {
    let data = [...studentClassrooms]
    data[index][event.target.name] = event.target.value
    setStudentClassrooms(data)
  }

  const handleStudentStatusInput = (event, index) => {
    //
    //
    let data = [...studentStatuses]
    data[index][event.target.name] = event.target.value
    setStudentStatuses(data)
  }

  const handleStudentImage = (e) => {
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      const image = reader.result
      setStudent({ ...student, image })
    }
  }

  const handleStudentAttachments = (e) => {
    const filesList = Object.values(e.target.files)

    setAttachments(filesList)
  }

  useEffect(() => {
    setNameValError('')
    setClassroomValError('')
    setStatusValError('')
  }, [student.firstName, student.lastName, studentClassrooms, studentStatuses])

  const handleSubmit = async (event) => {
    event.preventDefault()

    // show error message if name is not filled
    if (!student.firstName || !student.lastName) {
      setNameValError('Vul de naam van de leerling in')
      setCloseConfirmationWindowOn(false)
      return
    }

    // show error message if any classroom does not have the classroom's name and/or starting date
    let allClassroomsValid = true
    for (let i = 0; i < studentClassrooms.length; i++) {
      const classroom = studentClassrooms[i].classroom
      const startDate = studentClassrooms[i].startDate
      // const endDate = studentClassrooms[i].endDate

      // if (!classroom || !startDate || !endDate) {
      if (!classroom || !startDate) {
        allClassroomsValid = false

        break
      }
    }

    if (!allClassroomsValid) {
      setClassroomValError(
        'U hebt geen klas en/of begin datum en/of einddatum gekozen'
      )
      setCloseConfirmationWindowOn(false)
      return
    }

    let allStatusesValid = true
    for (let i = 0; i < studentStatuses.length; i++) {
      const status = studentStatuses[i].status
      const date = studentStatuses[i].date

      if (!status || !date) {
        allStatusesValid = false
        break
      }
    }

    if (studentStatuses.length === 0 || !allStatusesValid) {
      setStatusValError(
        'U heeft geen status of startdatum van de status gekozen'
      )
      setCloseConfirmationWindowOn(false)
      return
    }

    try {
      const formData = new FormData()

      formData.append('student', JSON.stringify(student))
      formData.append('studentClassrooms', JSON.stringify(studentClassrooms))
      formData.append('studentStatuses', JSON.stringify(studentStatuses))
      formData.append('studentNotes', JSON.stringify(notes))

      // formData.append('attachments', JSON.stringify(attachments)) // does not work
      attachments.forEach((element) => {
        formData.append('attachments', element) // each file should be appended separately,
        // we should provide the same name on the backend on route for multer
      })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axiosPrivate.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/students/student`,
        formData,
        config
      )

      if (isCloseConfirmationWindowOn) {
        navigate('/students')
      }

      if (!isCloseConfirmationWindowOn) {
        navigate(`/students/student/${data._id}`)
      }
    } catch (error) {
      setNameValError(error.response.data.message)
    }
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    }
  }

  return (
    <section className='popupContainer'>
      {isCloseConfirmationWindowOn && (
        <div className='closeModalContainer'>
           <div className='closeConfirmationBox bigclosebox'>
              <article>
                <h1>Opslaan voordat u afsluit?</h1>
                <button    onClick={() => {
                  setCloseConfirmationWindowOn(false)
                }}>
                  <X strokeWidth={1} stroke='gray'/>
                </button>
              </article>
              <p>
              Hier hebt u drie opties: klik op "Ja" om de wijzigingen op te slaan, "Nee" om ze te negeren, of annuleer door op de 'x' in de rechterbovenhoek te klikken.
              </p>
              <div className='confirmationButtonsContainer'>
              <button style={{maxWidth: 'fit-content'}}
                      onClick={(e) => {
                        handleSubmit(e)
                      }}
                >
                  Ja
                  <CircleCheckBig width='15' stroke='white'/>
                </button>
                <button className='nee-button' style={{maxWidth: 'fit-content'}}
                  onClick={() => {
                    setCloseConfirmationWindowOn(false)
                    navigate('/students')
                  }}
                >
                  Nee
                  <CircleSlash2 width='15' stroke='white'/>
                </button>
              </div>
            </div>

        </div>
      )}
      <button
        onClick={() => {
          setCloseConfirmationWindowOn(true)
        }}
        className='popupContainerClose'
      >
        <img alt='' src='/images/close.png'></img>
      </button>
      <div className='studentModule '>
        <div className='sectionContainer studentSection profile'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Profiel</h1>
            </div>
            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button
                  onClick={handleSubmit}
                  className='sectionControlButton'
                >
                  <Save width='17' stroke='white'/>
                </button>
              </div>
            </div>
          </div>
          {nameValError && <p className='cardErrorMessage'>{nameValError}</p>}
          <div className='studentScrollableBox'>
            <div className='studentProfileImageContainer'>
              <img
                alt='' src='/images/ribbon_left.jpg'
                className='profileImageRibbon leftRibbon'
              ></img>
              <img
                alt='' src='/images/ribbon_right.jpg'
                className='profileImageRibbon rightRibbon'
              ></img>
              <div className='studentProfileImageBox'>
                {student?.image === '' ? (
                  <UserRound size='100' strokeWidth={1} stroke='var(--mainbg)' />
                ) : (
                  <img alt='' src={student.image} />
                )}
                <div className='imageShadow'></div>
                <label className='studentProfileImageLabel'>
                  <input
                    type='file'
                    accept='image/*'
                    className='cardProfileImageInput'
                    onChange={handleStudentImage}
                  />
                </label>
              </div>
            </div>

            <div className='studentDetailsBox'>
              <label htmlFor='firstName'>Voornaam</label>
              <input
                type='text'
                id='firstName'
                name='firstName'
                value={student.firstName}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='lastName'>Achternaam</label>
              <input
                type='text'
                id='lastName'
                name='lastName'
                value={student.lastName}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='birthDate'>Geboortedatum</label>
              <input
                type='date'
                id='birthDate'
                name='birthDate'
                value={student.birthDate || ''}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='sex'>Geslacht</label>
              <select
                className='studentDetailsBoxLabelPlaceholder'
                id='sex'
                name='sex'
                onChange={handleStudentInput}
              >
                <option value={''}>--Kies een geslacht--</option>
                {sexList?.map((sex, index) => {
                  return (
                    <option value={sex} key={index}>
                      {sex}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='telephone'>Telefoon</label>
              <input
                type='text'
                id='telephone'
                name='telephone'
                value={student.telephone}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='email'>E-mailadres</label>
              <input
                type='email'
                id='email'
                name='email'
                value={student.email}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='arrivalDate'>Aankomstdatum Nederland</label>
              <input
                type='date'
                id='arrivalDate'
                name='arrivalDate'
                value={student.arrivalDate || ''}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='registrationDate'>
                Registratie Datum WereldWijd
              </label>
              <input
                type='date'
                id='registrationDate'
                name='registrationDate'
                value={student.registrationDate || ''}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='country'>Land van Herkomst</label>
              <input
                type='text'
                id='country'
                name='country'
                value={student.country}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='permitHolder'>Status Houder</label>
              <select
                id='permitHolder'
                name='permitHolder'
                onChange={handleStudentInput}
                value={student.permitHolder}
              >
                <option value={''}>--Kies een status--</option>
                {booleanList?.map((status, index) => {
                  return (
                    <option value={status} key={index}>
                      {status === 'true' ? 'Ja' : 'Nee'}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='organization'>Instantie</label>
              <select
                id='organization'
                name='organization'
                onChange={handleStudentInput}
                value={student.organization || ''}
              >
                <option value={''}>--Kies een instantie--</option>
                {organizations?.map((org, index) => {
                  return (
                    <option value={org._id} key={index}>
                      {org.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='contactPersonName'>Contactpersoon Naam</label>
              <input
                type='text'
                id='contactPersonName'
                name='contactPersonName'
                value={student.contactPersonName}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='contactPersonJobDescription'>
                Functie Contactpersoon
              </label>
              <input
                type='text'
                id='contactPersonJobDescription'
                name='contactPersonJobDescription'
                value={student.contactPersonJobDescription}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='contactPersonTelephone'>
                Contactpersoon Telefoon
              </label>
              <input
                type='text'
                id='contactPersonTelephone'
                name='contactPersonTelephone'
                value={student.contactPersonTelephone}
                onChange={handleStudentInput}
              ></input>
            </div>
            <div className='studentDetailsBox'>
              <label htmlFor='contactPersonEmail'>
                Contactpersoon E-mailadres
              </label>
              <input
                type='text'
                id='contactPersonEmail'
                name='contactPersonEmail'
                value={student.contactPersonEmail}
                onChange={handleStudentInput}
              ></input>
            </div>
          </div>
        </div>

        <div className='sectionContainer classrooms'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Klas</h1>
            </div>
            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button onClick={addClassroom} className='sectionControlButton'>
                <CirclePlus width='15' stroke='white'/>
                </button>
              </div>
            </div>
          </div>
          {classroomValError && (
            <p className='cardErrorMessage'>{classroomValError}</p>
          )}
          <div className='studentScrollableBox'>
            {studentClassrooms?.map((studentClassroom, index) => {
              return (
                <StudentClassroomCard
                  key={studentClassroom._id || studentClassroom.uniqueId}
                  index={index}
                  studentClassroom={studentClassroom}
                  studentClassrooms={studentClassrooms}
                  deleteClassroom={deleteClassroom}
                  handleStudentClassroomInput={handleStudentClassroomInput}
                  classrooms={classrooms}
                  formattedDate={formattedDate}
                />
              )
            })}
          </div>
        </div>

        <div className='sectionContainer statuses'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Statussen</h1>
            </div>
            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button onClick={addStatus} className='sectionControlButton'>
                <CirclePlus width='15' stroke='white'/>
                </button>
              </div>
            </div>
          </div>
          {statusValError && (
            <p className='cardErrorMessage'>{statusValError}</p>
          )}
          <div className='studentScrollableBox'>
            {studentStatuses?.map((studentStatus, index) => {
              return (
                <StudentStatusCard
                  key={studentStatus._id || studentStatus.uniqueId}
                  index={index}
                  studentStatus={studentStatus}
                  deleteStatus={deleteStatus}
                  handleStudentStatusInput={handleStudentStatusInput}
                  studentStatuses={studentStatuses}
                  booleanList={booleanList}
                />
              )
            })}
          </div>
        </div>

        <div className='sectionContainer attachments'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Bijlagen</h1>
            </div>
            <div className='sectionControlsContainer'>
              {attachments.length > 0 && (
                <div className='notificationMessageInControls'>
                  <p>
                    U kiest {attachments.length}{' '}
                    {attachments.length === 1 ? 'bestand' : 'bestanden'}
                  </p>
                </div>
              )}
              <div className='sectionControlContainer'>
                {/* button replaced with label in order to trigger the input */}
                <label className='sectionControlButton' htmlFor='attachments'>
                  <input
                    type='file'
                    id='attachments'
                    name='attachments'
                    accept='application/pdf,
                    application/vnd.ms-excel,
                    image/png,
                    // application/msword, // this lets choose only .doc
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document' // this lets choose only .docx
                    // if I turn them both on, only .doc work
                    onChange={handleStudentAttachments}
                    multiple
                    className='maskedAttachmentInput'
                  />
                  <div className='sectionControlImage'>
                  <CirclePlus width='15' stroke='white'/>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className='studentScrollableBox'>
            <div className='studentAttachmentsContainer'>
            </div>
          </div>
        </div>

        <div className='sectionContainer notes'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Aantekeningen</h1>
            </div>
            <div className='sectionControlsContainer'>
              <div className='sectionControlContainer'>
                <button
                  disabled={noteIndex !== null}
                  onClick={() => {
                    setFilterToggle(false)
                    setNewNoteToggle(!newNoteToggle)
                  }}
                  className={
                    newNoteToggle
                      ? 'sectionControlButtonShade'
                      : 'sectionControlButton'
                  }
                >
                     <CirclePlus width='15' stroke='white'/>
                </button>
              </div>
            </div>
          </div>
          {filterToggle && (
            <div className='noteCategoriesContainer'>
              <button className='noteCategoryButton'>Evaluatie</button>
              <button name='category' className='noteCategoryButton'>
                Status
              </button>
              <button name='category' className='noteCategoryButton'>
                Klas
              </button>
              <button name='category' className='noteCategoryButton'>
                Andere
              </button>
            </div>
          )}
          {newNoteToggle && (
            <div className='noteContainer'>
              <div className='noteInputBox'>
                <div className='noteHeaderCategoriesContainer'>
                  <label htmlFor='note'>Nieuwe Aantekening</label>{' '}
                  <div className='noteCategoriesContainer'>
                    {categoryNames?.map((category, index) => {
                      return (
                        <button
                          key={index}
                          name='category'
                          value={category}
                          onClick={(e) => handleNewNoteInput(e)}
                          className={`noteCategoryButton ${
                            newNote.category === category
                              ? 'chosenNoteCategory'
                              : ''
                          }`}
                        >
                          {category}
                        </button>
                      )
                    })}
                  </div>
                </div>
                <textarea
                  name='description'
                  value={newNote.description}
                  onChange={(e) => handleNewNoteInput(e)}
                  className='noteTextarea'
                  placeholder='Schrijf hier...'
                  id='note'
                ></textarea>
              </div>
              <div className='noteInfoControlsContainer'>
                <p>Gemaakt: {formatDate(formattedDate)}</p>

                <div className='confirmationButtonsContainer'>
               
                  <button className='opslaan_btn'
                    disabled={newNote.description === '' ? true : false}
                    onClick={saveNewNote}
                  >
                    Opslaan
                    <CircleCheckBig width='15' stroke='white'/>
                  </button>
                  <button onClick={cancelNewNote}  className='annuleren_btn nee-button'>
                    Annuleren
                    <CircleSlash2 width='15' stroke='white'/>
                  </button>
                </div>
              </div>
            </div>
          )}
          {noteIndex !== null && (
            <div className='noteContainer'>
              <div className='noteInputBox'>
                <div className='noteHeaderCategoriesContainer'>
                  <label htmlFor='note'>Edit Aantekening</label>
                  <div className='noteCategoriesContainer'>
                    {categoryNames?.map((category, index) => {
                      return (
                        <button
                          key={index}
                          name='category'
                          value={category}
                          onClick={(e) => handleEditedNoteInput(e)}
                          className={`noteCategoryButton ${
                            editedNote.category === category
                              ? 'chosenNoteCategory'
                              : ''
                          }`}
                        >
                          {category}
                        </button>
                      )
                    })}
                  </div>
                </div>
                <textarea
                  name='description'
                  value={editedNote.description}
                  onChange={(e) => handleEditedNoteInput(e)}
                  className='noteTextarea'
                  placeholder='Schrijf hier...'
                  id='note'
                ></textarea>
              </div>
              <div className='noteInfoControlsContainer'>
                <p>Gemaakt: {formatDate(editedNote.date)}</p>

                <div className='confirmationButtonsContainer'>
                  <button  style={{maxWidth: 'fit-content', padding: '3px 25px'}}
                    disabled={editedNote.description === '' ? true : false}
                    onClick={saveEditedNote}
                  >
                    Opslaan
                    <CircleCheckBig width='15' stroke='white'/>
                  </button>
                  <button onClick={cancelEditedNote}  style={{maxWidth: 'fit-content', padding: '3px 15px'}}className='nee-button'>
                    Annuleren
                    <CircleSlash2 width='15' stroke='white'/>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div
            className='studentScrollableBox'
            onSubmit={(e) => e.preventDefault()}
          >
            {notes.length > 0 && (
              <table className='list'>
                <thead>
                  <tr>
                    {noteHeaders?.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {notes?.map((element, index) => {
                    const isAdmin = auth.roles.includes(1991)
                    const isAuthor = auth.userId === element.author
                    return (
                      <tr key={index} onClick={() => openEditedNote(index)}>
                        <td className='cell px-for-td'>{formatDate(element.date)}</td>
                        <td className='cell px-for-td'>{element.authorName}</td>
                        <td className='cell px-for-td'>{element.category}</td>
                        <td className='cell px-for-td'>
                          <span title={element.description}>
                            {element.description.length > 20
                              ? `${element.description.substring(0, 20)}...`
                              : element.description}
                          </span>
                        </td>
                        <td className='cell trashcan_container px-for-td'>
                          <button
                            disabled={
                              (!isAdmin && !isAuthor) || noteIndex !== null // only admin or the author of the note can delete his note. While the note is open, no deleting
                            }
                            onClick={(e) => {
                              e.stopPropagation()
                              deleteNote(index)
                            }}
                            className={`noteIconContainer ${
                              (!isAdmin && !isAuthor) || noteIndex !== null
                                ? 'faded'
                                : ''
                            }`}
                          >
                              <Trash2 width='20' stroke='var(--dark_orange)'/>
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewStudent
