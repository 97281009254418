import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import OrganizationCard from './OrganizationCard'
import OrganizationNewCard from './OrganizationNewCard'
import { CirclePlus } from 'lucide-react'

const Organizations = () => {
  const axiosPrivate = useAxiosPrivate()
  const [organizations, setOrganizations] = useState([])

  const [
    addNewOrganizationActivated,
    setAddNewOrganizationActivated,
  ] = useState(false)

  useEffect(() => {
    const fetchAllOrganizations = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/organizations`
        )
        setOrganizations(data)
      } catch (error) {
      }
    }
    fetchAllOrganizations()
  }, [])

  const handleInput = (event, index) => {
    const data = [...organizations]
    data[index][event.target.name] = event.target.value
    setOrganizations(data)
  }

  const handleDeleteOrganization = async (id) => {
    const { data } = await axiosPrivate.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/organizations/organization/${id}`
    )
    setOrganizations(data)
  }

  const addNewOrganization = () => {
    setAddNewOrganizationActivated(true)
  }

  return (
    <>
      <div className='sectionHeaderContainer'>
        <div className='sectionTitleContainer'>
          <div className='sectionTitlePoint'></div>
          <h1 className='sectionTitle'>Instanties</h1>
        </div>
        <div className='sectionControlsContainer'>
          <div className='sectionControlContainer'>
            <button
              onClick={addNewOrganization}
              className='sectionControlButton'
            >
              <CirclePlus stroke='white'  width='15'/>
            </button>
          </div>
        </div>
      </div>
      <div className='cardsContainer'>
        {addNewOrganizationActivated && (
          <OrganizationNewCard
            setOrganizations={setOrganizations}
            addNewOrganizationActivated={addNewOrganizationActivated}
            setAddNewOrganizationActivated={setAddNewOrganizationActivated}
          />
        )}
        {organizations?.map((organization, index) => {
          return (
            <OrganizationCard
              key={index}
              index={index}
              organization={organization}
              organizations={organizations}
              handleInput={handleInput}
              handleDeleteOrganization={handleDeleteOrganization}
            />
          )
        })}
      </div>
    </>
  )
}

export default Organizations
