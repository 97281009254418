import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { CirclePlus, CircleSlash2, CircleCheckBig } from 'lucide-react'

const UserCard = ({
  index,
  user,
  users,
  setUsers,
  handleInput,
  handleRole,
  handleDeleteUser,
  handleUserImage,
}) => {
  const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const [confirmMessage, setConfirmMessage] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const [imagePreview, setImagePreview] = useState('')

  // sends request to check if there is any notes that this user created
  const [deleteButtonActivated, setDeleteButtonActivated] = useState(false)
  // in response it saving a message 'Can be deleted'
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState('')
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('')

  // useEffect(() => {
  //
  // }, [preDeleteStatus])

  useEffect(() => {
    const message = setTimeout(() => {
      setConfirmMessage('')
    }, 1800)

    //
    return () => {
      clearTimeout(message)
    }
  }, [confirmMessage])

  const updateUser = async () => {
    try {
      const formData = new FormData()

      formData.append('image', users[index].image)

      formData.append('user', JSON.stringify(users[index]))

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axiosPrivate.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/user/${users[index]._id}`,
        formData,
        config
      )
      setConfirmMessage(data.message)
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Geen serverreactie')
      } else if (error.response?.status === 400) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Update mislukt')
      }
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [
    users[index].name,
    users[index].email,
    users[index].roles,
    users[index].status,
  ])

  // const [isResponseArrived, setResponseArrived] = useState(false)

  const checkUser = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/user/${user._id}`
      )
      //

      setDeleteConfirmationMessage(data.message)
    } catch (error) {
      if (error.response?.status === 400) {
        setDeleteErrorMessage(error.response.data.message)
      }
    } finally {
      setDeleteButtonActivated(true)
    }
  }

  return (
    <div key={index} className='cardContainer'>
      {deleteButtonActivated ? (
        <div className='cardDeleteConfirmationBox'>
          {deleteErrorMessage && (
            <>
              <p className='deleteConfirmationMessage'>{deleteErrorMessage}</p>
              <div className='confirmationButtonsContainer'>
                <button
                  onClick={() => {
                    setDeleteErrorMessage('')
                    setDeleteConfirmationMessage('')
                    setDeleteButtonActivated(false)
                  }}
                >
                  Oké
                </button>
              </div>
            </>
          )}
          {deleteConfirmationMessage && (
            <>
              <p className='deleteConfirmationMessage'>
                {deleteConfirmationMessage}
              </p>
              <div className='confirmationButtonsContainer'>
                <button
                  onClick={() => {
                    setDeleteErrorMessage('')
                    setDeleteConfirmationMessage('')
                    setDeleteButtonActivated(false)
                  }}
                >
                  Annuleren
                   <CircleSlash2 width='15' stroke='white'/>
                </button>
                <button
                  onClick={() => {
                    handleDeleteUser(user._id)
                    setDeleteErrorMessage('')
                    setDeleteConfirmationMessage('')
                    setDeleteButtonActivated(false)
                  }}
                >
                  Jazeker
                    <CircleCheckBig width='15' stroke='white'/>
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className='cardDeleteButtonContainer'>
          <button
            className='cardDeleteButton'
            // disabled={
            //   auth.email === user.email && auth.roles.includes(1991)
            //     ? true
            //     : false
            // }
            onClick={checkUser}
          >
            <img className='cardDeleteImage' src='/images/delete.png' />
          </button>
        </div>
      )}
      {errMsg && (
        <div className='cardMessageContainer'>
          <p className='cardErrorMessage'>{errMsg}</p>
        </div>
      )}
      {confirmMessage && (
        <div className='cardMessageContainer'>
          <p className='cardConfirmMessage'>{confirmMessage}</p>
        </div>
      )}

      <div className='cardProfileImageContainer'>
        {/* if there is a preview image has been chosen => show it, if not => show what has been set before, if not => show default image */}
        <img alt=''
          src={
            imagePreview
              ? imagePreview
              : users[index]?.image?.fileName
              ? `${process.env.REACT_APP_BACKEND_URL}/uploads/userImages/${user?.image?.fileName}`
              : '/images/profile.png'
          }
        ></img>

        <div className='imageShadow'></div>
        <label className='cardProfileImageLabel'>
          <input
            type='file'
            name='image'
            accept='image/*'
            className='cardProfileImageInput'
            onChange={(e) => {
              const fileObject = Object.values(e.target.files)[0]
              handleUserImage(index, fileObject)
              setImagePreview(URL.createObjectURL(fileObject))
            }}
          />
        </label>
      </div>

      <div className='cardInputContainer'>
        {/* <label htmlFor={`name${index}`}>Naam</label> */}
        <input
          className='sharedInput'
          placeholder='Naam'
          id={`name${index}`}
          type='text'
          name='name'
          value={users[index].name}
          onChange={(e) => handleInput(e, index)}
        ></input>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='E-mailadres'
          className='sharedInput'
          id={`email${index}`}
          type='email'
          name='email'
          value={users[index].email}
          onChange={(e) => handleInput(e, index)}
          // disabled={
          //   auth.email === user.email && auth.roles.includes(1991)
          //     ? true
          //     : false
          // }
        ></input>
      </div>
      <div className='cardCheckboxContainer'>
        <div>
          <input
            // disabled={
            //   auth.email === user.email && auth.roles.includes(1991)
            //     ? true
            //     : false
            // }
            type='checkbox'
            checked={'admin' in users[index].roles}
            id={`admin${index}`}
            name='admin'
            value='admin'
            onChange={(e) => handleRole(index, 'admin')}
          />
          <label className='sharedInput' htmlFor={`admin${index}`}>
            Beheerder
          </label>
        </div>
        <div>
          <input
            type='checkbox'
            checked={'staff' in users[index].roles}
            id={`staff${index}`}
            name='staff'
            value='staff'
            onChange={(e) => handleRole(index, 'staff')}
          />
          <label className='sharedInput' htmlFor={`staff${index}`}>
            Personeel
          </label>
        </div>
        {/* <div>
          <input
            type='checkbox'
            checked={'teacher' in users[index].roles}
            // checked={roles.find((role) => role === 'teacher')}
            id={`teacher${index}`}
            name='teacher'
            value='teacher'
            onChange={(e) => handleRole(index, 'teacher')}
          />
          <label className='sharedInput' htmlFor={`teacher${index}`}>
            Docent
          </label>
        </div> */}
      </div>
      <div className='cardStatusContainer'>
        <select
          name='status'
          className='sharedInput'
          // disabled={
          //   auth.email === user.email && auth.roles.includes(1991)
          //     ? true
          //     : false
          // }
          onChange={(event) => handleInput(event, index)}
          value={users[index].status}
        >
          <option value={true}>Actief</option>
          <option value={false}>Inactief</option>
        </select>
      </div>
      <div className='cardUpdateButtonContainer'>
        <button onClick={updateUser}>Update</button>
      </div>
    </div>
  )
}

export default UserCard
