import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { CircleSlash2, CircleCheckBig } from 'lucide-react'

const ClassroomCard = ({
  index,
  classroom,
  classrooms,
  handleInput,
  handleDeleteClassroom,
}) => {
  const axiosPrivate = useAxiosPrivate()
  const [confirmMessage, setConfirmMessage] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const [deleteButtonActivated, setDeleteButtonActivated] = useState(false)
  const [preDeleteStatus, setPreDeleteStatus] = useState('')

  const checkClassroom = async () => {
    try {
      setDeleteButtonActivated(true)
      const { data } = await axiosPrivate.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/classrooms/classroom/${classroom._id}`
      )
      //
      setPreDeleteStatus(data.message)
    } catch (error) {
      console.log('error', error)
    }
  }

  const updateClassroom = async () => {
    //
    try {
      const { data } = await axiosPrivate.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/classrooms/classroom/${classrooms[index]._id}`,
        classrooms[index]
      )
      setConfirmMessage(data.message)
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Geen serverreactie')
      } else if (error.response?.status === 400) {
        setErrMsg(error.response.data.message)
      } else if (error.response?.status === 409) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Update mislukt')
      }
    }
  }

  useEffect(() => {
    const message = setTimeout(() => {
      setConfirmMessage('')
    }, 1800)

    //
    return () => {
      clearTimeout(message)
    }
  }, [confirmMessage])

  useEffect(() => {
    setErrMsg('')
  }, [classrooms[index].name])

  return (
    <div className='cardContainer'>
      {deleteButtonActivated ? (
        <div className='cardDeleteConfirmationBox'>
          <p className='deleteConfirmationMessage'>
            {preDeleteStatus === 'Can be deleted'
              ? 'Weet u zeker dat u deze instantie wilt verwijderen?'
              : 'Deze instantie kan niet worden verwijderd'}
          </p>
          {preDeleteStatus === 'Can be deleted' ? (
            <div className='confirmationButtonsContainer'>
              <button
                onClick={() => {
                  handleDeleteClassroom(classroom._id)
                  setDeleteButtonActivated(false)
                }}
              >
                Jazeker
                <CircleCheckBig width='15' stroke='white'/>
              </button>
              <button className='nee-button' onClick={() => setDeleteButtonActivated(false)}>
                Annuleren
                <CircleSlash2 width='15' stroke='white'/>
              </button>
            </div>
          ) : (
            <button onClick={() => setDeleteButtonActivated(false)}>Oké</button>
          )}
        </div>
      ) : (
        <div className='cardDeleteButtonContainer'>
          <button className='cardDeleteButton' onClick={checkClassroom}>
            <img className='cardDeleteImage' src='/images/delete.png' alt='' />
          </button>
        </div>
      )}
      {errMsg && (
        <div className='cardMessageContainer'>
          <p className='cardErrorMessage'>{errMsg}</p>
        </div>
      )}
      {confirmMessage && (
        <div className='cardMessageContainer'>
          <p className='cardConfirmMessage'>{confirmMessage}</p>
        </div>
      )}
      <div className='cardProfileImageContainer'>
        <img style={{ padding: 10 }} src='/images/classroom.png' alt=''></img>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='Naam'
          className='cellInput'
          id={`name${index}`}
          type='text'
          name='name'
          value={classrooms[index].name}
          onChange={(e) => handleInput(e, index)}
        ></input>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='Soort'
          className='cellInput'
          id={`type${index}`}
          type='type'
          name='type'
          value={classrooms[index].type}
          onChange={(e) => handleInput(e, index)}
        ></input>
      </div>

      <div className='cardStatusContainer'>
        <select
          name='status'
          onChange={(e) => handleInput(e, index)}
          value={classrooms[index].status}
        >
          <option value={true}>Actief</option>
          <option value={false}>Inactief</option>
        </select>
      </div>
      <div className='cardUpdateButtonContainer'>
        <button onClick={updateClassroom}>Update</button>
      </div>
    </div>
  )
}

export default ClassroomCard
