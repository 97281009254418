const generateRandomColors = (count) => {
  const colors = []
  for (let i = 0; i < count.length; i++) {
    const r = Math.floor(Math.random() * 256)
    const g = Math.floor(Math.random() * 256)
    const b = Math.floor(Math.random() * 256)
    colors.push(`rgba(${r}, ${g}, ${b}, 0.6)`)
  }
  return colors
}

export default generateRandomColors

// Hook vs. Function: If your random color generation logic needs to interact
// with React state or lifecycle events,
// or if it needs to access React context or props,
// then creating a custom React hook might make more sense.
// Hooks are a way to encapsulate logic that is closely related
// to a component and can access React-specific features.
