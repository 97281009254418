import axios from 'axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/refresh`,
      {
        withCredentials: true,
      }
    )
    setAuth((prev) => {
      return {
        ...prev,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
        userId: response.data.userId,
        userName: response.data.userName,
        email: response.data.email,
        // image: response.data.image,
      }
    })
    return response.data.accessToken
  }
  return refresh
}

export default useRefreshToken
