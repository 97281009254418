import React, { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useNavigate, useLocation } from 'react-router-dom'
import { CirclePlus } from 'lucide-react'
import '../styles/users.css'

import UserCard from './UserCard'
import UserNewCard from './UserNewCard'

const Users = () => {
  const axiosPrivate = useAxiosPrivate()

  const [users, setUsers] = useState([])

  const [addNewUserActivated, setAddNewUserActivated] = useState(false)

  useEffect(() => {
    // const controller = new AbortController()
    const fetchUsers = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users`,
          {
            // signal: controller.signal,
          }
        )
        //
        setUsers(data)
      } catch (error) {
        // navigate('/login', { state: { from: location }, replace: true })
      }
    }
    fetchUsers()

    // return () => {
    //   controller.abort()
    // }
  }, [])

  const handleInput = (event, index) => {
    let data = [...users]
    data[index][event.target.name] = event.target.value

    setUsers(data)
  }

  const handleUserImage = (index, file) => {
    let data = [...users]
    data[index].image = file
    setUsers(data)
  }

  const handleRole = (index, role) => {
    let data = [...users]

    // how can I hide the role's number implication and still show the correct role?
    const roleNumber = role === 'admin' ? 1991 : role === 'staff' ? 4859 : 8619

    const roleExists = role in data[index].roles

    if (!roleExists) {
      data[index].roles = { ...data[index].roles, [role]: roleNumber }
    } else {
      delete data[index].roles[role]
    }

    setUsers(data)
  }

  const handleDeleteUser = async (id) => {
    const { data } = await axiosPrivate.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/user/${id}`
    )
    setUsers(data)
  }

  const addNewUser = () => {
    setAddNewUserActivated(true)
  }

  return (
    <>
      <div className='sectionHeaderContainer'>
        <div className='sectionTitleContainer'>
          <div className='sectionTitlePoint'></div>
          <h1 className='sectionTitle'>Gebruikers</h1>
        </div>
        <div className='sectionControlsContainer'>
          <div className='sectionControlContainer'>
            <button onClick={addNewUser} className='sectionControlButton'>
              <CirclePlus stroke='white'  width='15'/>
            </button>
          </div>
        </div>
      </div>
      <div className='cardsContainer'>
        {addNewUserActivated && (
          <UserNewCard
            setUsers={setUsers}
            addNewUserActivated={addNewUserActivated}
            setAddNewUserActivated={setAddNewUserActivated}
          />
        )}
        {users?.map((user, index) => {
          return (
            <UserCard
              key={user._id}
              index={index}
              user={user}
              users={users}
              handleInput={handleInput}
              handleRole={handleRole}
              setUsers={setUsers}
              handleDeleteUser={handleDeleteUser}
              handleUserImage={handleUserImage}
            />
          )
        })}
      </div>
    </>
  )
}

export default Users
