import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { axiosPrivate } from '../api/axios'

const UserNewCard = ({
  setUsers,
  addNewUserActivated,
  setAddNewUserActivated,
}) => {
  // const { auth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  // const [confirmMessage, setConfirmMessage] = useState('')
  // const [errMsg, setErrMsg] = useState('')

  const [imagePreview, setImagePreview] = useState('')

  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    status: true,
    roles: {},
    image: {},
  })
  const [errMsg, setErrMsg] = useState('')

  // without saving on close button click
  const closeNewUser = () => {
    setAddNewUserActivated(false)
  }

  const handleInput = (event) => {
    let data = { ...newUser }
    data[event.target.name] = event.target.value
    setNewUser(data)
  }

  const handleRole = (role) => {
    let data = { ...newUser }

    const roleNumber = role === 'admin' ? 1991 : role === 'staff' ? 4859 : 8619

    const roleExists = role in data.roles

    if (!roleExists) {
      data.roles = { ...data.roles, [role]: roleNumber }
    } else {
      delete data.roles[role]
    }

    setNewUser(data)
  }

  useEffect(() => {}, [newUser])

  const allFilled =
    Boolean(newUser.name) &&
    Boolean(newUser.email) + Object.keys(newUser.roles).length != 0

  const addNewUser = async () => {
    // if (allFilled) {
    try {
      const formData = new FormData()
      formData.append('image', newUser.image)

      formData.append('newUser', newUser)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axiosPrivate.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/user`,
        newUser,
        config
      )
      setAddNewUserActivated(false)
      setUsers(data)
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Geen serverreactie')
      } else if (error.response?.status === 400) {
        setErrMsg(error.response.data.message)
      } else if (error.response?.status === 409) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Update mislukt')
      }
    }
    // }
  }

  useEffect(() => {
    setErrMsg('')
  }, [newUser.name, newUser.email, newUser.roles])

  const handleNewUserImage = (e) => {
    const fileObject = Object.values(e.target.files)[0]
    setImagePreview(URL.createObjectURL(fileObject))
    let data = { ...newUser }
    data.image = fileObject
    setNewUser(data)
  }

  return (
    <div
      className={`cardContainer ${
        addNewUserActivated ? 'fancyCardContainer' : ''
      }`}
    >
      <div className='cardDeleteButtonContainer'>
        <button onClick={closeNewUser} className='cardDeleteButton'>
          <img className='cardDeleteImage' src='/images/delete.png' />
        </button>
      </div>
      {errMsg && (
        <div className='cardMessageContainer'>
          <p className='cardErrorMessage'>{errMsg}</p>
        </div>
      )}
      <div className='cardProfileImageContainer'>
        <img src={imagePreview ? imagePreview : '/images/profile.png'}></img>
        <div className='imageShadow'></div>
        <label className='cardProfileImageLabel'>
          <input
            type='file'
            accept='image/*'
            className='cardProfileImageInput'
            onChange={(e) => handleNewUserImage(e)}
          />
        </label>
      </div>
      <div className='cardInputContainer'>
        <input
          placeholder='Naam'
          className='cellInput'
          id='name'
          type='text'
          name='name'
          value={newUser.name}
          onChange={(e) => handleInput(e)}
        ></input>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='E-mailadres'
          className='cellInput'
          id={`email`}
          type='email'
          name='email'
          value={newUser.email}
          onChange={(e) => handleInput(e)}
        ></input>
      </div>
      <div className='cardCheckboxContainer'>
        <div>
          <input
            type='checkbox'
            checked={'admin' in newUser.roles}
            id='admin'
            name='admin'
            value='admin'
            onChange={(e) => handleRole('admin')}
          />
          <label htmlFor='admin'>Beheerder</label>
        </div>
        <div>
          <input
            type='checkbox'
            checked={'staff' in newUser.roles}
            id='staff'
            name='staff'
            value='staff'
            onChange={(e) => handleRole('staff')}
          />
          <label htmlFor='staff'>Personeel</label>
        </div>
      </div>
      <div className='cardStatusContainer'>
        <select
          name='status'
          onChange={(event) => {
            let data = { ...newUser }
            data[event.target.name] = event.target.value
            setNewUser(data)
          }}
          value={newUser.status}
        >
          <option value={true}>Actief</option>
          <option value={false}>Inactief</option>
        </select>
      </div>
      <div className='cardUpdateButtonContainer'>
        <button onClick={addNewUser}>Creëren</button>
      </div>
    </div>
  )
}

export default UserNewCard
