import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'
import StudentsList from '../components/StudentsList'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import '../styles/students.css'
import '../styles/tables.css'
import useExportToExcel from '../hooks/useExportToExcel'
import Loader from '../components/Loader'
import { CirclePlus, Search, Download, Filter } from 'lucide-react'

const Students = () => {
  const axiosPrivate = useAxiosPrivate()
  const { tableRef, exportTableToExcel } = useExportToExcel()
  const [students, setStudents] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [studentsOnOnePage, setStudentsOnOnePage] = useState(false)

  const navigate = useNavigate()
  const [isFirstEffectCompleted, setFirstEffectCompleted] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams() // get access to the query parameters from the URL, holds the extracted query parameters in the form of an object

  const [organizationsQueryFromURL, setOrganizationsQueryFromURL] = useState([])
  const [classroomsQueryFromURL, setClassroomsQueryFromURL] = useState([])
  const [inactiveQueryFromURL, setInactiveQueryFromURL] = useState('')

  const [nameQueryFromURL, setNameQueryFromURL] = useState('')

  // to join to the existing query if there any organization(s) is checked
  const organizationsParams = searchParams.getAll('organization').length
  // to join to the existing query if there any classroom(s) is checked
  const classroomsParams = searchParams.getAll('classroom').length

  const inactiveParam = searchParams.get('inactive')

  const nameParam = searchParams.get('name')

  // whenever there is a change in location, we want to update the states of filters based on queries, including initial render
  useEffect(() => {
    setOrganizationsQueryFromURL([])
    setClassroomsQueryFromURL([])
    setInactiveQueryFromURL('')
    setNameQueryFromURL('')
    if (organizationsParams || classroomsParams || inactiveParam) {
      const tempOrganizationsQueryFromURL = searchParams.getAll('organization')
      setOrganizationsQueryFromURL(tempOrganizationsQueryFromURL)

      const tempClassroomsQueryFromURL = searchParams.getAll('classroom')
      setClassroomsQueryFromURL(tempClassroomsQueryFromURL)

      const tempInactiveQueryFromURL = searchParams.get('inactive')
      setInactiveQueryFromURL(tempInactiveQueryFromURL)
    }

    if (nameParam) {
      const tempNameQueryFromURL = searchParams.get('name')
      setNameQueryFromURL(tempNameQueryFromURL)
    }
    setFirstEffectCompleted(true)
  }, [window.location.search])

  const [page, setPage] = useState(1)
  const [pagesCount, setPagesCount] = useState(0)
  const [totalStudentsCount, setTotalStudentsCount] = useState(0)

  const handlePreviousPage = () => {
    setPage((prev) => {
      if (prev === 1) return prev
      return prev - 1
    })
  }
  const handleNextPage = () => {
    setPage((prev) => {
      if (prev === pagesCount) return prev
      return prev + 1
    })
  }

  const fetchStudents = async () => {
    setLoading(true)
    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/api/students?page=${page}`

      if (nameParam) {
        url += `&name=${nameQueryFromURL}`
      }

      if (organizationsParams) {
        // ['647ef10e431584ff68584c70'] // encoded
        const encodedOrganizations = organizationsQueryFromURL?.map(
          (organization) => encodeURIComponent(organization)
        )
        // if our query already exists than add up to it by '&',
        // otherwise start with '?'
        url += `&organization=${encodedOrganizations.join('&organization=')}`
      }

      if (classroomsParams) {
        const encodedClassrooms = classroomsQueryFromURL?.map((classroom) =>
          encodeURIComponent(classroom)
        )
        url += `&classroom=${encodedClassrooms.join('&classroom=')}`
      }

      if (inactiveParam) {
        const encodedStatuses = encodeURIComponent(inactiveQueryFromURL)
        url += `&inactive=${encodedStatuses}`
      }

      if (studentsOnOnePage) {
        url += `&studentsOnOnePage=true`
      }

      const response = await axiosPrivate.get(url, {
        // signal: controller.signal,
      })
      // [{student: {…}, classrooms: Array(2)}, …] => classrooms {_id: '649002837099d7427dcb1cd4', name: 'Lasser'}, endDate: null, startDate: "2023-07-19T00:00:00.000Z", status: "true", student: "64b5044fee362937dea88e4c", _id: "64b51085b36cd942c107d296"}
      setStudents(response.data.list)
      setPagesCount(response.data.pagination.pagesCount)
      setTotalStudentsCount(response.data.pagination.studentsCount)
    } catch (error) {
      // setErrorMessage(error.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  // once any state is updated from a query, we fetch students
  useEffect(() => {
    if (isFirstEffectCompleted) {
      fetchStudents()
    }
  }, [
    isFirstEffectCompleted,
    JSON.stringify(organizationsQueryFromURL),
    JSON.stringify(classroomsQueryFromURL),
    JSON.stringify(inactiveQueryFromURL),
    JSON.stringify(nameQueryFromURL),
    page,
    studentsOnOnePage,
  ])

  const [filterToggle, setFilterToggle] = useState(false)

  const [classrooms, setClassrooms] = useState([])
  const [organizations, setOrganizations] = useState([])

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/classrooms`
        )
        setClassrooms(data)
      } catch (error) {
        setErrorMessage(error.response.data.message)
      }
    }
    fetchClassrooms()
  }, [])

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const { data } = await axiosPrivate.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/organizations`
        )
        setOrganizations(data)
      } catch (error) {
        setErrorMessage(error.response.data.message)
      }
    }

    fetchOrganizations()
  }, [])

  const [isOrganizationsOpen, setOrganizationsOpen] = useState(false)
  const [isClassroomsOpen, setClassroomsOpen] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)

  const toggleOrganizations = () => {
    setOrganizationsOpen(!isOrganizationsOpen)
  }

  const toggleClassrooms = () => {
    setClassroomsOpen(!isClassroomsOpen)
  }

  const toggleStatus = () => {
    setIsStatusOpen(!isStatusOpen)
  }

  return (
    <main>
      <div className='sectionContainer'>
        {studentsOnOnePage && (
          <div className='downloadAllStudentsPopupContainer'>
            <div className='downloadAllStudentsPopup'>
              {isLoading ? (
                <>
                  <div className='sectionTitleContainer'>
                    <Loader />
                  </div>
                  <p className='authText'>
                    Het samenstellen van de studentenlijst
                  </p>

                  <button
                    className='wordedButton'
                    onClick={() => setStudentsOnOnePage(false)}
                  >
                    Annuleren
                  </button>
                </>
              ) : (
                <>
                  <p className='authText'>
                    De studentenlijst wordt gevormd. U kunt de lijst nu
                    downloaden
                  </p>
                  <button
                    className='wordedButton'
                    onClick={() => setStudentsOnOnePage(false)}
                  >
                    Terug
                  </button>

                  <button
                    onClick={() => exportTableToExcel('studenten_lijst')}
                    className='wordedButton'
                  >
                    Downloaden
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        <div className='sectionHeaderContainer'>
          <div className='sectionTitleContainer'>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className='sectionTitlePoint'></div>
                <h1 className='sectionTitle'>Studentlijst</h1>
              </>
            )}
          </div>
          <div className='sectionControlsContainer'>
            <div className='sectionControlContainer'
            >
              <form
                className='sectionSearchContainer'
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  className='sectionSearchInput'
                  type='search'
                  value={nameQueryFromURL}
                  onChange={(event) => {
                    const { value } = event.target

                    const trimmedQuery = value.trim()

                    setNameQueryFromURL(trimmedQuery)

                    setOrganizationsQueryFromURL([])

                    setClassroomsQueryFromURL([])

                    setInactiveQueryFromURL('')

                    setSearchParams((prev) => {
                      const updatedParams = new URLSearchParams(prev)

                      updatedParams.delete('organization')
                      updatedParams.delete('classroom')
                      updatedParams.delete('inactive')

                      updatedParams.set('name', value)
                      return updatedParams
                    })

                    if (trimmedQuery === '') {
                      setSearchParams((prev) => {
                        const updatedParams = new URLSearchParams(prev)
                        updatedParams.delete('name')
                        return updatedParams
                      })
                    }
                  }}
                  // onKeyUp={handleClear}
                  placeholder='Zoeken...'
                />
                <button className='sectionSearchButton' type='submit'>
                  <Search stroke='white' width='18'/>
                </button>
              </form>
            </div>
            <div className='sectionControlContainer'>
              <button
                onClick={() => {
                  setStudentsOnOnePage(true)
                }}
                className='sectionControlButton'
              >
                <Download stroke='white'  width='15'/>
              </button>
            </div>
            <div className='sectionControlContainer'>
              <button
                onClick={() => setFilterToggle(!filterToggle)}
                className={
                  filterToggle
                    ? 'sectionControlButtonShade'
                    : 'sectionControlButton'
                }
              >
                <Filter stroke='white'  width='15'/>
              </button>
            </div>
            <div className='sectionControlContainer'>
              <button
                onClick={() => navigate('/students/student/add-student')}
                className='sectionControlButton'
              >
                <CirclePlus stroke='white'  width='15'/>
              </button>
            </div>
          </div>
        </div>
        <div className='studentsListFiltersContainer'>
          {isLoading ? (
            <div className='listPaginationContainer'>
            </div>
          ) : totalStudentsCount ? (
            <div className='listPaginationContainer'>
              <StudentsList
                students={students}
                setStudents={setStudents}
                setTotalStudentsCount={setTotalStudentsCount}
                tableRef={tableRef}
              />
              <div className='listControls' style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className='listCount' style={{marginLeft: '0px', maxWidth: '170px'}}>
                  <p >
                    Totaal studenten: <b>{totalStudentsCount}</b>
                  </p>
                </div>
                <div className='listPagination'>
                 <div style={{display: 'flex', gap: '5px'}}>
                 <button
                    disabled={page === 1}
                    onClick={handlePreviousPage}
                    className='paginationButton'
                  >
                    <img alt='' className='arrow' src='/images/previous.png' />
                  </button>

                  {Array.from(
                    { length: pagesCount },
                    (_, index) => index + 1
                  )?.map((pageNumber) => {
                    if (
                      pageNumber <= 2 ||
                      pageNumber > pagesCount - 2 ||
                      Math.abs(pageNumber - page) <= 1
                    ) {
                      return (
                        <button
                          key={pageNumber}
                          onClick={() => setPage(pageNumber)}
                          className={`paginationButton ${
                            page === pageNumber
                              ? 'selectedPaginationButton'
                              : ''
                          }`}
                        >
                          {pageNumber}
                        </button>
                      )
                    } else if (
                      pageNumber === 3 ||
                      pageNumber === pagesCount - 2
                    ) {
                      // Show ellipsis (...) for skipped pages
                      return <span key={pageNumber}>...</span>
                    }
                    return null
                  })}

                  <button
                    disabled={page === pagesCount}
                    onClick={handleNextPage}
                    className='paginationButton'
                  >
                    <img alt='' className='arrow' src='/images/next.png' />
                  </button>
                 </div>
                </div>
                <div style={{ width: '170px' }}></div>
              </div>
            </div>
          ) : (
            <div className='listPaginationContainer'>
              <p className='infoOnLoading'>Geen studenten gevonden</p>
            </div>
          )}

          {filterToggle && (
            <div className='studentsFilterContainer'>
              <div className='studentsFilterBox'>
                <div
                  onClick={toggleOrganizations}
                  className='filterCollapseHeader'
                >
                  <p className='filterSectionTitle'>Organizations</p>
                  <div>
                    {isOrganizationsOpen ? (
                      <img alt='' className='arrow' src='/images/arrow_up.png' />
                    ) : (
                      <img alt='' className='arrow' src='/images/arrow_down.png' />
                    )}
                  </div>
                </div>
                {isOrganizationsOpen &&
                  organizations?.map((element, index) => {
                    const isChecked = organizationsQueryFromURL.some(
                      (el) => el === element._id
                    )

                    return (
                      <div key={index} className='filterCategories'>
                        <div className='filterCategoryBox'>
                          <input
                            checked={isChecked}
                            id={element._id}
                            value={element._id}
                            type='checkbox'
                            onChange={(event) => {
                              const { value, checked } = event.target

                              setNameQueryFromURL('')

                              const tempArray = checked
                                ? [...organizationsQueryFromURL, value]
                                : organizationsQueryFromURL.filter(
                                    (el) => el !== value
                                  )
                              setOrganizationsQueryFromURL(tempArray) // ['64889b70f2d396a48afecff0', '647d89b0d695881ae21c67f1']

                              // attach query on the address bar
                              // ?organization=647d89b0d695881ae21c67f1&organization=6422c9891cff31dd54e582da
                              if (checked) {
                                setSearchParams((prev) => {
                                  const updatedParams = new URLSearchParams(
                                    prev
                                  )
                                  updatedParams.delete('name')

                                  updatedParams.append('organization', value)
                                  return updatedParams
                                })
                              } else {
                                setSearchParams((prev) => {
                                  const updatedParams = new URLSearchParams()

                                  prev.forEach((paramValue, paramName) => {
                                    if (
                                      paramName === 'organization' &&
                                      paramValue === value
                                    ) {
                                      return
                                    }
                                    updatedParams.append(paramName, paramValue)
                                  })

                                  return updatedParams.toString()
                                })
                              }
                            }}
                          />
                          <label htmlFor={element._id}>{element.name}</label>
                        </div>
                      </div>
                    )
                  })}
              </div>

              <div className='studentsFilterBox'>
                <div
                  onClick={toggleClassrooms}
                  className='filterCollapseHeader'
                >
                  <p className='filterSectionTitle'>Classrooms</p>
                  <div>
                    {isClassroomsOpen ? (
                      <img className='arrow' src='/images/arrow_up.png' />
                    ) : (
                      <img className='arrow' src='/images/arrow_down.png' />
                    )}
                  </div>
                </div>

                {isClassroomsOpen &&
                  classrooms?.map((element, index) => {
                    const isChecked = classroomsQueryFromURL.some((el) => {
                      return el === element._id
                    })

                    return (
                      <div key={index} className='filterCategories'>
                        <div className='filterCategoryBox'>
                          <input
                            checked={isChecked}
                            id={element._id}
                            value={element._id}
                            type='checkbox'
                            onChange={(event) => {
                              const { value, checked } = event.target

                              setNameQueryFromURL('')

                              const tempArray = checked
                                ? [...classroomsQueryFromURL, value]
                                : classroomsQueryFromURL.filter(
                                    (el) => el !== value
                                  )
                              setClassroomsQueryFromURL(tempArray)

                              if (checked) {
                                setSearchParams((prev) => {
                                  const updatedParams = new URLSearchParams(
                                    prev
                                  )
                                  updatedParams.delete('name')
                                  updatedParams.append('classroom', value)
                                  return updatedParams
                                })
                              } else {
                                setSearchParams((prev) => {
                                  const updatedParams = new URLSearchParams()

                                  prev.forEach((paramValue, paramName) => {
                                    if (
                                      paramName === 'classroom' &&
                                      paramValue === value
                                    ) {
                                      return
                                    }
                                    updatedParams.append(paramName, paramValue)
                                  })

                                  return updatedParams.toString()
                                })
                              }
                            }}
                          />
                          <label htmlFor={element._id}>{element.name}</label>
                        </div>
                      </div>
                    )
                  })}
              </div>

              <div className='studentsFilterBox'>
                <div onClick={toggleStatus} className='filterCollapseHeader'>
                  <p className='filterSectionTitle'>Status</p>
                  <div>
                    {isStatusOpen ? (
                      <img className='arrow' src='/images/arrow_up.png' />
                    ) : (
                      <img className='arrow' src='/images/arrow_down.png' />
                    )}
                  </div>
                </div>

                {isStatusOpen && (
                  <div className='filterCategories'>
                    <div className='filterCategoryBox'>
                      <input
                        checked={inactiveQueryFromURL === 'true'}
                        id='inactive'
                        value='true'
                        type='checkbox'
                        onChange={(e) => {
                          const { value, checked } = e.target

                          // to empty the search function
                          setNameQueryFromURL('')

                          const updatedStatus = checked ? value : ''
                          setInactiveQueryFromURL(updatedStatus)
                          // setStatusSearch(updatedStatus)

                          if (checked) {
                            setSearchParams((prev) => {
                              const updatedParams = new URLSearchParams(prev)
                              updatedParams.delete('name')
                              updatedParams.delete('inactive')
                              updatedParams.append('inactive', value)
                              return updatedParams
                            })
                          } else {
                            setSearchParams((prev) => {
                              const updatedParams = new URLSearchParams(prev)
                              updatedParams.delete('inactive')
                              return updatedParams
                            })
                          }
                        }}
                      />
                      <label htmlFor='inactive'>
                        Inclusief Inactieve Studenten
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default Students