import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setErrMsg('')
  }, [email])

  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  const emailRef = useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/password-reset/create-link`,
        {
          email,
        }
      )

      if (data.message === 'Success') {
        setSuccess(true)
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('Geen serverreactie')
      } else if (err.response?.status === 403 || err.response?.status === 404) {
        setErrMsg(err.response.data.message)
      } else {
        setErrMsg('Aanmelden mislukt')
      }
    }
  }
  return (
    <>
      {!success && (
        <section className='forgetPasswordContainer'>
          <p className='authText'>
            Vul hieronder uw e-mailadres in. <br />
            We sturen een e-mail waarmee een nieuw wachtwoord kan worden
            aangemaakt.
          </p>
          <form className='authInputsContainer' onSubmit={handleSubmit}>
            {errMsg && <p className='authErrorMessage'>{errMsg}</p>}
            <input
              type='email'
              id='email'
              ref={emailRef}
              autoComplete='off'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              placeholder='E-mailadres'
              onInvalid={(e) =>
                e.target.setCustomValidity('Vul uw e-mailadres in')
              }
              onInput={(e) => e.target.setCustomValidity('')}
            />
            <button className='authButton' type='submit'>
              Krijg link
            </button>
            <button className='authButton' type='button' onClick={goBack}>
              Ga terug
            </button>
          </form>
        </section>
      )}
      {success && (
        <section className='forgetPasswordContainer'>
          <p className='authText'>
            Controleer uw e-mailbox (spam). <br />
            We leggen u in een e-mail uit hoe u wachtwoord opnieuw wordt
            ingesteld.
            <br />U kunt dit venster sluiten
          </p>
        </section>
      )}
    </>
  )
}

export default ForgotPassword
