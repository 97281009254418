import React, { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

const ClassroomNewCard = ({
  setClassrooms,
  addNewClassroomActivated,
  setAddNewClassroomActivated,
}) => {
  const axiosPrivate = useAxiosPrivate()

  const [newClassroom, setNewClassroom] = useState({
    name: '',
    type: '',
    status: true,
  })
  const [errMsg, setErrMsg] = useState('')

  const closeNewClassroom = () => {
    setAddNewClassroomActivated(false)
  }

  const handleInput = (event) => {
    let data = { ...newClassroom }
    data[event.target.name] = event.target.value
    setNewClassroom(data)
  }

  const addNewClassroom = async () => {
    try {
      const { data } = await axiosPrivate.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/classrooms/classroom`,
        newClassroom
      )
      setAddNewClassroomActivated(false)
      setClassrooms(data)
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Geen serverreactie')
      } else if (error.response?.status === 400) {
        setErrMsg(error.response.data.message)
      } else if (error.response?.status === 409) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Update mislukt')
      }
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [newClassroom.name])

  return (
    <div
      className={`cardContainer ${
        addNewClassroomActivated ? 'fancyCardContainer' : ''
      }`}
    >
      <div className='cardDeleteButtonContainer'>
        <button onClick={closeNewClassroom} className='cardDeleteButton'>
          <img className='cardDeleteImage' src='/images/delete.png' />
        </button>
      </div>
      {errMsg && (
        <div className='cardMessageContainer'>
          <p className='cardErrorMessage'>{errMsg}</p>
        </div>
      )}
      <div className='cardProfileImageContainer'>
        <img style={{ padding: 10 }} src='/images/classroom.png'></img>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='Naam'
          className='cellInput'
          id='name'
          type='text'
          name='name'
          value={newClassroom.name}
          onChange={(e) => handleInput(e)}
        ></input>
      </div>

      <div className='cardInputContainer'>
        <input
          placeholder='Soort'
          className='cellInput'
          id='type'
          type='type'
          name='type'
          value={newClassroom.type}
          onChange={(e) => handleInput(e)}
        ></input>
      </div>

      <div className='cardStatusContainer'>
        <select
          name='status'
          onChange={(e) => handleInput(e)}
          value={newClassroom.status}
        >
          <option value={true}>Actief</option>
          <option value={false}>Inactief</option>
        </select>
      </div>

      <div className='cardUpdateButtonContainer'>
        <button onClick={addNewClassroom}>Creëren</button>
      </div>
    </div>
  )
}
export default ClassroomNewCard
