import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import '../styles/navbar.css'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import axios from 'axios'
import { UsersRound, SquareCheckBig, MessageSquareMore, BarChartBig, Settings, Clock12 } from 'lucide-react'

const Navbar = () => {
  const { auth, setAuth } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const locationName = pathname.split('/')[1]

  const logout = async () => {
    navigate('/login')
    setAuth({})
    // create endpoint to handle two things:
    // deletes refreshToken from DB
    // erases refreshToken from httpOnly cookie
    try {
      await axios(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
        withCredentials: true,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <nav>
      <NavLink className='logoContainer' to='/'>
        <img alt=''
          style={{ objectFit: 'fill', borderRadius: '10px', width: '40px' , background: 'var(--light_orange)', padding: '5px'}}
          src='/images/logo.png'
        ></img>
        
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          `${isActive ? 'chosenNavigationButton' : 'navigationButton'}`
        }
        to='/students'
      >
        <div
          className={locationName === 'students' ? 'selectedLine' : ''}
        ></div>
        <UsersRound width={44}/>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `${isActive ? 'chosenNavigationButton' : 'navigationButton'}`
        }
        to='/lists'
      >
        <div className={locationName === 'lists' ? 'selectedLine' : ''}></div>
          <SquareCheckBig width={44}/>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          `${isActive ? 'chosenNavigationButton' : 'navigationButton'}`
        }
        to='/evaluations'
      >
        <div
          className={locationName === 'evaluations' ? 'selectedLine' : ''}
        ></div>
        <MessageSquareMore width={44}/>
      </NavLink>
      
      <NavLink
        className={({ isActive }) =>
          `${isActive ? 'chosenNavigationButton' : 'navigationButton'}`
        }
        to='/statistics'
      >
        <div
          className={locationName === 'statistics' ? 'selectedLine' : ''}
        ></div>
        <BarChartBig width={44}/>
      </NavLink>

      <div className='navbarRoundButtonsContainer'>
        <NavLink
          className={({ isActive }) =>
            `${isActive ? 'chosenNavigationButton' : 'navigationButton'}`
          }
          to='/admin'
        >
          <div className={locationName === 'admin' ? 'selectedLine' : ''}></div>
          <Settings width={44} />
        </NavLink>
        <button onClick={logout}style={{border: 'none'}} className='navigationButton'>
          <Clock12 width={44} stroke="rgb(78, 30, 134)"  strokeWidth={2.3}/>
        </button>
      </div>
    </nav>
  )
}

export default Navbar
