import React, { useState } from 'react'
import { CircleSlash2, CircleCheckBig } from 'lucide-react'

const StudentStatusCard = ({
  index,
  studentStatus,
  studentStatuses,
  deleteStatus,
  handleStudentStatusInput,
  booleanList,
}) => {
  const [deleteButtonActivated, setDeleteButtonActivated] = useState(false)

  return (
    <div
      className={
        index === 0
          ? 'studentActiveCardContainer'
          : 'studentInactiveCardContainer'
      }
    >
      {deleteButtonActivated ? (
        <div
          className={
            index === 0
              ? 'studentCardDeleteConfirmationActiveBox'
              : 'studentCardDeleteConfirmationInactiveBox'
          }
        >
          <p className='deleteConfirmationMessage'>
            Weet u zeker dat u deze status wilt verwijderen?
          </p>
          <div className='confirmationButtonsContainer'>
            <button
              onClick={(e) => {
                deleteStatus(studentStatus._id || studentStatus.uniqueId)
                setDeleteButtonActivated(false)
              }}
            >
              Ja
              <CircleCheckBig width='15' stroke='white'/>
            </button>
            <button className='nee-button' onClick={() => setDeleteButtonActivated(false)}>
              Nee
               <CircleSlash2 width='15' stroke='white'/>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='studentCardBoxDeleteButtonContainer'>
            <button
              className='studentCardBoxDeleteButton'
              onClick={(e) => {
                setDeleteButtonActivated(true)
              }}
            >
              <img className='cardDeleteImage' src='/images/delete.png' alt='' />
            </button>
          </div>
        </>
      )}

      <div className='studentCardBox'>
        <div className='studentCardSubSection'>
          <label htmlFor='status'>Status</label>
          <select
            id='status'
            name='status'
            onChange={(e) => handleStudentStatusInput(e, index)}
            value={`${studentStatuses[index].status}`}
            disabled
          >
            <option value={''}>--Kies een status--</option>
            {booleanList?.map((status, index) => {
              return (
                <option value={status} key={index}>
                  {status === 'true' ? 'Actief' : 'Inactief'}
                </option>
              )
            })}
          </select>
        </div>
        <div className='studentCardSubSection'>
          <label htmlFor='date'>
            Datum
          </label>
          <input
            type='date'
            id='date'
            name='date'
            value={`${studentStatuses[index].date}`}
            onChange={(e) => handleStudentStatusInput(e, index)}
          ></input>
        </div>
      </div>
    </div>
  )
}

export default StudentStatusCard
