import React, { useState, useEffect } from 'react'
import '../styles/admin.css'
import Users from '../components/Users'
import Organizations from '../components/Organizations'
import Classrooms from '../components/Classrooms'
import Header from '../components/Header'

const Admin = () => {
  const [chosenCategory, setChosenCategory] = useState('Gebruikers') // by default

  const categories = ['Gebruikers', 'Instanties', 'Klassen']

  return (
    <main className='mainbg'>
      {/* <Header /> */}
      <section className='subsectionContainer'>
        <div className='sectionContainer stripContainer'>
          <div className='sectionHeaderContainer'>
            <div className='sectionTitleContainer'>
              <div className='sectionTitlePoint'></div>
              <h1 className='sectionTitle'>Categorieën</h1>
            </div>
          </div>
          <div className='categoriesContainer'>
            {categories?.map((category, index) => {
              return (
                <div
                  onClick={() => setChosenCategory(category)}
                  className={`categoryButton ${
                    category === chosenCategory ? 'activeCategory' : ''
                  }`}
                  key={index}
                >
                  {category}
                </div>
              )
            })}
          </div>
        </div>
        <div className='sectionContainer'>
          {chosenCategory === 'Gebruikers' ? (
            <Users />
          ) : chosenCategory === 'Klassen' ? (
            <Classrooms />
          ) : (
            <Organizations />
          )}
        </div>
      </section>
    </main>
  )
}

export default Admin
