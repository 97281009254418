import React from 'react'
// import { useNavigate, Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import '../styles/homepage.css'

const Home = () => {

  const { auth } = useAuth()

  return (
    <main className='mainbg homepagina'>
      <div className='sectionContainer'>
        <div className='sectionHeaderContainer'>
          <div className='sectionTitleContainer'>
            <div className='sectionTitlePoint'></div>
            <h1 className='sectionTitle'>Homepagina</h1>
          </div>
        </div>

        <header className="header">
                <h1>Hello {auth.userName}! </h1>
                <p>Welcome to the Teacher Journal App!</p>
            </header>
            
            <section className="about-app">
                <h2>About Our App</h2>
                <p>
                    At Stichting WereldWijd, we are committed to supporting the integration and development of asylum seekers and refugees. Our new Teacher Journal App is designed to enhance the educational experience for both teachers and students. This tool allows educators to:
                </p>
                <ul>
                    <li><strong>Easily Document Student Progress:</strong> Keep track of individual student achievements and areas for improvement.</li>
                    <li><strong>Streamline Communication:</strong> Share updates and insights with fellow educators and staff.</li>
                    <li><strong>Enhance Personalized Learning:</strong> Tailor your teaching approach based on detailed student profiles.</li>
                </ul>
            </section>
            
            <section className="why-use">
                <h2>Why Use Our App?</h2>
                <ul>
                    <li><strong>User-Friendly Interface:</strong> Intuitive design for quick and easy journal entries.</li>
                    <li><strong>Comprehensive Records:</strong> Store detailed notes, grades, and feedback in one secure place.</li>
                    <li><strong>Collaborative Tools:</strong> Facilitate better coordination among teaching staff.</li>
                </ul>
            </section>
            
            <footer className="footer">
                <p>
                    Join us in fostering a supportive and effective learning environment for our diverse student community. Together, we can make a meaningful impact on their educational journey.
                </p>
                <p>
                    For more information about our foundation and initiatives, visit <a href="https://www.stichtingwereldwijd.nl/" target="_blank" rel="noopener noreferrer">Stichting WereldWijd</a>.
                </p>
            </footer>

      </div>
    </main>
  )
}

export default Home


 