import React from 'react'

const Loader = () => {
  return (
    <>
      <div className='sectionTitlePointAnimatedOutside'>
        <div className='sectionTitlePointAnimatedInside'></div>
      </div>
      <h1 className='sectionTitle'>Bezig met laden...</h1>
    </>
  )
}

export default Loader
