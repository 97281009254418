import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'

const ResetPassword = () => {
  const [validUrl, setValidUrl] = useState(false)
  const navigate = useNavigate()
  const param = useParams()

  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/
  // (?=.*[!@#$%]) include special character?

  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)

  const [matchPassword, setMatchPassword] = useState('')
  const [validPasswordMatch, setValidPasswordMatch] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password))
    setValidPasswordMatch(password === matchPassword)
  }, [password, matchPassword])

  useEffect(() => {
    setErrMsg('')
  }, [password, matchPassword])

  const url = `${process.env.REACT_APP_BACKEND_URL}/password-reset/${param.id}/${param.token}`

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        const { data } = await axios.get(url)

        if (data.message === 'Valid Url') {
          setValidUrl(true)
        }
      } catch (error) {
        setValidUrl(false)
      } finally {
        setLoading(false)
      }
    }
    verifyUrl()
  }, [param, url])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!validPassword) {
      setErrMsg(
        'Uw wachtwoord moet 6 tot 24 tekens lang zijn. Moet hoofdletters en kleine letters en een cijfer bevatten'
      )
      return
    }

    if (!validPasswordMatch) {
      setErrMsg('Herhaal wachtwoord moet overeenkomen met wachtwoord')
      return
    }

    try {
      const { data } = await axios.post(url, { password })
      // if there is any error,
      // the catch block should capture it
      // and the rest of the code in try wouldn't run,
      // do I really need that if statement?
      if (data.message === 'Success') {
        setSuccess(true)
      }
      // navigate('/login')
    } catch (error) {}
  }

  return (
    <>
      {!isLoading && !validUrl && (
        <section className='forgetPasswordContainer'>
          <p className='authText'>Aangegeven link werkt niet</p>
          <button className='authButton' onClick={() => navigate('/login')}>
            Ga naar inloggen
          </button>
        </section>
      )}
      {isLoading && (
        <section className='forgetPasswordContainer'>
          <p className='authText'>Bezig met laden...</p>
        </section>
      )}
      {!isLoading && validUrl && !success && (
        <section className='forgetPasswordContainer'>
          <p className='authText'>Typ uw nieuwe wachtwoord</p>
          <form className='authInputsContainer' onSubmit={handleSubmit}>
            {errMsg && <p className='authErrorMessage'>{errMsg}</p>}
            <div className='authInputsContainer'>
              <input
                type='password'
                placeholder='Wachtwoord'
                name='password'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                onInvalid={(e) =>
                  e.target.setCustomValidity('Vul uw nieuwe wachtwoord')
                }
                onInput={(e) => e.target.setCustomValidity('')}
              />
            </div>
            <div className='authInputsContainer'>
              <input
                type='password'
                placeholder='Herhaal Wachtwoord'
                onChange={(e) => setMatchPassword(e.target.value)}
                required
                onInvalid={(e) =>
                  e.target.setCustomValidity('Herhaal uw nieuwe wachtwoord')
                }
                onInput={(e) => e.target.setCustomValidity('')}
              />
            </div>
            <button
              className='authButton'
              type='submit'
              // disabled={!validPassword || !validPasswordMatch ? true : false}
            >
              Submit
            </button>
          </form>
        </section>
      )}
      {!isLoading && validUrl && success && (
        <section className='forgetPasswordContainer'>
          <p className='authText'>
            U heeft een nieuw wachtwoord aangemaakt. U kunt nu inloggen in de
            applicatie.
          </p>
          <button className='authButton' onClick={() => navigate('/login')}>
            Ga naar inloggen
          </button>
        </section>
      )}
    </>
  )
}

export default ResetPassword
