import { Routes, Route } from 'react-router-dom'
import './styles/app.css'
// import './fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf'
import Home from './pages/Home'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Unauthorized from './pages/Unauthorized'
import RequireAuth from './components/RequireAuth'
import Admin from './pages/Admin'
import Students from './pages/Students'
import Layout from './components/Layout'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import NewStudent from './pages/newStudent/NewStudent'
import StudentProfile from './pages/StudentProfile'
import PersistLogin from './components/PersistLogin'
import Lists from './pages/Lists'
import NewAttendanceListForTeacher from './pages/NewAttendanceListForTeacher'
import AttendanceListForTeacherProfile from './pages/AttendanceListForTeacherProfile'
import NewAttendanceListForOrganization from './pages/NewAttendanceListForOrganization'
import Evaluation from './pages/Evaluations'
import Statistics from './pages/Statistics'

const App = () => {
  return (
    <div className='App'>
      <Routes>
        {/* private routes */}
        <Route element={<Layout />}>
          <Route element={<PersistLogin />}>
            {/* anyone who has accessToken can see this page, since they are staff by default */}
            <Route element={<RequireAuth allowedRoles={[1991, 4859]} />}>
              <Route element={<Unauthorized />} path='/unauthorized' />
              <Route element={<Home />} path='/' />
              <Route element={<Students />} path='/students' />
              <Route
                element={<NewStudent />}
                path='/students/student/add-student'
              />
              <Route
                element={<StudentProfile />}
                path='/students/student/:id'
              />

              {/* lists */}
              <Route element={<Lists />} path='/lists' />
              <Route
                element={<NewAttendanceListForTeacher />}
                path='/lists/attendance-list-for-teacher/new'
              />
              <Route
                element={<AttendanceListForTeacherProfile />}
                path='/lists/attendance-list-for-teacher/:id'
              />

              <Route
                element={<NewAttendanceListForOrganization />}
                path='/lists/attendance-list-for-organization/new'
              />

              <Route element={<Statistics />} path='/statistics' />
              {/* <Route element={<AttendanceReport />} path='/reports' /> */}
            </Route>
            {/* anyone who has accessToken and is authorized as an admin can see 
            this page */}
            <Route element={<RequireAuth allowedRoles={[1991]} />}>
              <Route element={<Admin />} path='/admin' />
              <Route element={<Evaluation />} path='/evaluations' />
            </Route>
          </Route>
        </Route>

        {/* public routes */}
        <Route element={<Login />} path='/login' />
        <Route element={<ForgotPassword />} path='/forgot-password' />
        <Route element={<ResetPassword />} path='/password-reset/:id/:token' />
        <Route element={<NotFound />} path='*' />
      </Routes>
    </div>
  )
}

export default App
