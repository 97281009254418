import { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import axios from 'axios'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import '../styles/login.css'


const Login = () => {
  const { setAuth, persist, setPersist } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    setErrMsg('')
  }, [email, password])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth`,
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )

      const accessToken = response?.data?.accessToken
      const roles = response?.data?.roles
      const userId = response?.data?.userId
      const userName = response?.data?.userName
      // const image = response?.data?.image

      setAuth({ userId, userName, email, roles, accessToken })
      setEmail('')
      setPassword('')
      navigate(from, { replace: true }) // we don't want to store in history this page on back button click
    } catch (error) {
      if (!error?.response) {
        setErrMsg('Geen serverreactie')
      } else if (
        error.response?.status === 400 ||
        error.response?.status === 401 ||
        error.response?.status === 403 ||
        error.response?.status === 404
      ) {
        setErrMsg(error.response.data.message)
      } else {
        setErrMsg('Aanmelden mislukt')
      }
      // errRef.current.focus()
    }
  }

  const togglePersist = () => {
    setPersist((prev) => !prev)
  }

  useEffect(() => {
    localStorage.setItem('persist', persist)
  }, [persist])

  return (
    <div className='container_for_authContainer'>
      <section className='authContainer'>

        <img alt=''
          className='login_logo'
          src='/images/logo-full.png'
        ></img>
        {/* <div className='logo_container'>

          
          <div className='headwords'>
            <p className='organizationNamePrefix'>Stichting</p>
            <h1 className='organizationName'>WereldWijd</h1>
          </div>

        </div> */}

        {errMsg ? (
          <div className='authErrorMessageContainer'>
            <p className='authErrorMessage'>{errMsg}</p>
          </div>
        ) : (
          <div className='authErrorMessageContainer'></div>
        )}


        <form className='authInputsContainer' onSubmit={handleSubmit}>
          <input
            id='email'
            autoComplete='off'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
            onInvalid={(e) => e.target.setCustomValidity('Vul uw e-mailadres in')}
            onInput={(e) => e.target.setCustomValidity('')}
            // type='email'
            type='text'
            // if I put the email type in combination with onInvalid attribute, it annoys the user at every symbol input by telling to include "@"
            // does not using email type make the app less secure?
            placeholder='E-mailadres'
          />

          <input
            type='password'
            id='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
            onInvalid={(e) =>
              e.target.setCustomValidity(
                'U kunt het wachtwoordveld niet leeg laten'
              )
            }
            onInput={(e) => e.target.setCustomValidity('')}
            placeholder='Wachtwoord'
          />
          <button className='authButton'>Inloggen</button>
        </form>
        <div className='authPersistContainer'>
          <input
            type='checkbox'
            id='persist'
            onChange={togglePersist}
            checked={persist}

          />
          <label htmlFor='persist'>Vertrouw op dit apparaat</label>
        </div>

        <Link className='authForgotPassword' to='/forgot-password'>
          Wachtwoord vergeten?
        </Link>
      </section>
    </div>
  )
}

export default Login
